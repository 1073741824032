import React from "react";
import Header from "../components/Header";
import styled from "styled-components";
import Joyride, { STATUS } from "react-joyride";
import { useState,useEffect } from "react";
import { media } from "../utils/media";
import { Navigate, useNavigate } from "react-router-dom";
import { onLogoutLandingPageClickCT } from "../utils/clevertap";

const FrontPage3 = () => {
  const handleLinkedInAuth = () => {
    // Redirect the user to the LinkedIn authentication page
    window.location.href =
      "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=8658fm7b644ues&redirect_uri=https://api.benchmarks.digital/linkedin/callback&state=STATE&scope=r_liteprofile%20r_emailaddress";
  };

  const navigate=useNavigate()
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));

  const handleInvite = () => {
    window.location.href = "/signup";
  };

  useEffect(()=>{
    const lastAccessed=window.localStorage.getItem("lastAccessed");
    if(lastAccessed)
    {
      const redirectRep = IndustryDict[lastAccessed];
      navigate(`/Report3/?val=${redirectRep.report_name}&key=${redirectRep.id}&filter=${redirectRep.filter}&filter_value=${redirectRep.filter_value}`)
    }
    onLogoutLandingPageClickCT()
   },[])

  const [steps] = useState([
    {
      target: ".HeaderHome", // Using the scoped class name
      content: "This is Home button",
    },

    {
      target: ".HeaderIndustry",
      content: "Sector Button",
    },
    {
      target: ".HeaderCompany",
      content: "Companies button",
    },
    {
      target: ".HeaderBrands",
      content: "Brands Button",
    },
  ]);

  const [run, setRun] = useState(true);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    }
  };

  return (
    <div style={{objectFit:"cover"}}>
      {/* <Joyride
        callback={handleJoyrideCallback}
        continuous={true}
        run={run}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        steps={steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      /> */}
      <Header />
      <RedseerDiv>
        <img src="/Images/byredseer.png" alt="elephant" />
      </RedseerDiv>
      <ContentDiv>
        <ImageDiv>
          <img
            style={{ height: "60vh" }}
            src="/Images/elephant.png"
            alt="elephant"
          />
        </ImageDiv>
        <TextDiv>
          <div className="maintext">Ringside view of</div>
          <div className="maintext" style={{ color: "#E93735" }}>
            India’s internet
          </div>
          <div className="maintext">economy .</div>
          <div style={{ marginTop: "10px" }}>
            <div className="smalltext">
              A comprehensive business strategy tool for{" "}
            </div>
            <div className="smalltext">
              corporates, investors and brands.{" "}
              <span style={{ fontWeight: 500 }}>Access by</span>
            </div>
            <div className="smalltext">
              <span style={{ fontWeight: 500 }}>invitation only</span>
            </div>
          </div>
          {window.localStorage.getItem("loginStatus") === "true" ? (
            <Lovediv>Loving the product ?</Lovediv>
          ) : (
            <Lovediv></Lovediv>
          )}
          <div>
            <ButtonsArr>
              {window.localStorage.getItem("loginStatus") === "true" ? (
                <button onClick={() => (window.location.href = "/invitations")}>
                  Invite Others
                </button>
              ) : (
                <>
                <PrimaryButton onClick={() => window.location.href = "/signin3"}>
                    <ButtonContent>
                      Sign In
                    </ButtonContent>
                  </PrimaryButton>
                <SecondaryButton onClick={handleInvite}>
                <ButtonContent>
                  Request Invite
                </ButtonContent>
              </SecondaryButton>
              </>
              )}
              {/* <button onClick={handleLinkedInAuth}>
                  <div style={{display:'flex',alignItems:'center', justifyContent:'center'}}><img src = '/Images/linkedin3.png' alt = 'linkedin' style={{height:'17px', width:'auto', marginRight:'10px'}}/><span>Request Invite</span></div>
                </button> */}
            </ButtonsArr>
          </div>
        </TextDiv>
      </ContentDiv>
      <div className="footerfront">
        <div className="footerexpander"></div>
        {/* <div>Trusted by 133+ brands</div> */}
        {/* <div><img className = 'footerimg' src = '/Images/swiggy.jpeg' alt=  'img'/><img className = 'footerimg' src = '/Images/swiggy.jpeg' alt=  'img'/></div> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          Trusted by 133+ clients
        </div>
        <Footerdiv>
          <img className="footerimg" src="/Images/guber.png" alt="img" />
          <img className="footerimg" src="/Images/gsequioa.png" alt="img" />
          <img className="footerimg" src="/Images/gflipkart.png" alt="img" />
          <img className="footerimg" src="/Images/gdelhivery.png" alt="img" />
          <img className="footerimg" src="/Images/gbajaj.png" alt="img" />
        </Footerdiv>
      </div>
    </div>
  );
};

export default FrontPage3;

const Footerdiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
  margin-top: 2vh;
  flex-grow: 1;
  flex-wrap: wrap;
  ${media.small`
     gap: 25px;
    `}
`;

const RedseerDiv = styled.div`

  padding-left: 30px;
  /* border-bottom:1px solid black; */
  /* only for fullscreen */
  @media (min-width: 768px) {
    padding: 20px;
    padding-left: 3.5vw;
  }
`;
const ContentDiv = styled.div`
  padding-left: 30px;
  @media (min-width: 768px) {
    display: flex;
    gap: 60px;
    padding-left: 140px;
  }
`;

const ImageDiv = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const TextDiv = styled.div`
  margin-top: 40px;
`;

const ButtonsArr = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 16px;
  button {
    color: white;
    background-color: #0099ff;
    border: none;
    height: 50px;
    border-radius: 5px;
    width: 190px;
  }
 ${media.small`
    margin-right: 52px;
    margin-top: 0px;
 `}
`;
const Lovediv = styled.div`
  height: 10px;
  margin-top: 10px;
  color: #717171;
`;

const PrimaryButton = styled.button`
  color: white;
  background-color: #0099ff;
  border: none;
  height: 50px;
  border-radius: 5px;
  width: 211px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #0077cc;
    transform: translateY(-1px);
  }
`;

const SecondaryButton = styled.button`
  color: #0099ff;
  background-color: white;
  border: 1px solid #0099ff;
  height: 50px;
  border-radius: 5px;
  width: 211px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #0077cc;
    transform: translateY(-1px);
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;