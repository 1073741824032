import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { onCompanyProfileClickCT } from "../../../utils/clevertap";
import { media } from "../../../utils/media";
import { useNavigate } from "react-router-dom";


const SearchBar = ({ visibleCompanies = null }) => {
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  const [activeIndex, setActiveIndex] = useState(-1);
  const navigate=useNavigate()
  const handleKeyDown = (e) => {
    if (visibleCompanies.length === 0) return;

    if (e.key === "ArrowDown") {
      setActiveIndex((prevIndex) =>
        prevIndex < visibleCompanies.length - 1 ? prevIndex + 1 : 0
      );
    } else if (e.key === "ArrowUp") {
      setActiveIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : visibleCompanies.length - 1
      );
    } else if (e.key === "Enter" && activeIndex !== -1) {
      const selectedCompany = visibleCompanies[activeIndex];
      if (
        IndustryDict &&
        Boolean(IndustryDict[selectedCompany.company_profile_id])
      ) {
        const url = `/Report3/?val=${
          IndustryDict[selectedCompany.company_profile_id].report_name
        }&key=${
          IndustryDict[selectedCompany.company_profile_id].id
        }&filter=${
          IndustryDict[selectedCompany.company_profile_id].filter
        }&filter_value=${
          IndustryDict[selectedCompany.company_profile_id].filter_value
        }`;
        navigate(url);
      }
      selectedCompany.sector_profile_id &&
        onCompanyProfileClickCT(
          IndustryDict[selectedCompany.company_profile_id].report_name,
          IndustryDict[selectedCompany.company_profile_id].id,
          IndustryDict[selectedCompany.sector_profile_id].report_name,
          IndustryDict[selectedCompany.sector_profile_id].id
        );
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [visibleCompanies, activeIndex]);

  return (
    <SearchBarContainer>
      {visibleCompanies.length > 0 && visibleCompanies.length < 100 ? (
        <CompaniesTable>
          <tbody>
            {visibleCompanies.map((comp, index) => (
              <TableRow
                key={comp.company_profile_id}
                className={index === activeIndex ? "active" : ""}
              >
                <TableCell
                  onClick={() => {
                    if (
                      IndustryDict &&
                      Boolean(IndustryDict[comp.company_profile_id])
                    ) {
                      const url = `/Report3/?val=${
                        IndustryDict[comp.company_profile_id].report_name
                      }&key=${
                        IndustryDict[comp.company_profile_id].id
                      }&filter=${
                        IndustryDict[comp.company_profile_id].filter
                      }&filter_value=${
                        IndustryDict[comp.company_profile_id].filter_value
                      }`;
                      window.open(url, "_blank");
                    }
                    comp.sector_profile_id &&
                      onCompanyProfileClickCT(
                        IndustryDict[comp.company_profile_id].report_name,
                        IndustryDict[comp.company_profile_id].id,
                        IndustryDict[comp.sector_profile_id].report_name,
                        IndustryDict[comp.sector_profile_id].id
                      );
                  }}
                >
                  {comp.player_display_name}
                </TableCell>
              </TableRow>
            ))}
          </tbody>
        </CompaniesTable>
      ) : null}
    </SearchBarContainer>
  );
};

export default SearchBar;

const SearchBarContainer = styled.div`
  z-index: 100;
  background: none;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const CompaniesTable = styled.table`
  border-collapse: collapse;
  background-color: #ffffff;
  position: absolute;
  width: 20%;
  box-shadow: 2px 2px 4px 0px #00000040;

  ${media.medium`
    width:15%;
  `}
  ${media.xlarge`
    width:9%;
  `}
  ${media.large`
    width:10%;
  `}
  ${media.small`
    width:20%;
  `}
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 4px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: #0099fe;
  }

  &.active {
    background-color: #0099fe;
    color: #ffffff;
  }
`;

const NoDataMessage = styled.p`
  font-size: 14px;
  color: #555;
`;
