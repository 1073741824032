import React from "react";
import styled from "styled-components";
import {
  useParams,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useEffect } from "react";
import Header from "../components/Header";
import PowerBiFrame from "../components/PowerBiFrame/PowerBiFrame";
import PowerBiFrame3 from "../components/PowerBiFrame3/PowerBiFrame3";
import MyContext from "../utils/contexts/MyContext";
import browsee from "@browsee/web-sdk";
import { useState } from "react";
import { AUTO_LOGOUT_TIME } from "../constants/constants";

// import

const Report3 = () => {
  const search = useLocation().search;
  const val = new URLSearchParams(search).get("val");
  const key = new URLSearchParams(search).get("key");

  window.localStorage.setItem("report", val);
  window.localStorage.setItem("rep_id", key);
  const filter = new URLSearchParams(search).get("filter");
  const filter_value = new URLSearchParams(search).get("filter_value");
  const industry_id = new URLSearchParams(search).get("industry_id");
  const email = new URLSearchParams(search).get("email");
  const source = new URLSearchParams(search).get("source");
  const content_id = new URLSearchParams(search).get("content_id");
  const content_type = new URLSearchParams(search).get("content_type");
  const newsletter_name = new URLSearchParams(search).get("newsletter_name");
  const newsletter_month = new URLSearchParams(search).get("newsletter_month");
  const newsletter_user = new URLSearchParams(search).get("newsletter_user");
  const newsletter_email = new URLSearchParams(search).get("newsletter_email");

  let init_node = key?.toString();
  const navigate = useNavigate();
  const client_id = new URLSearchParams(search).get("client_id");
  const backend_token = new URLSearchParams(search).get("backend_token");
  const pseudo_email = new URLSearchParams(search).get("pseudo_email");
  const user_name = new URLSearchParams(search).get("name");
  const is_admin = new URLSearchParams(search).get("is_admin");

  if (client_id) {
    window.localStorage.setItem("clientID", client_id);
    window.localStorage.setItem("loginStatus", "true");
  }
  if (backend_token) {
    window.localStorage.setItem("token", backend_token);
    window.localStorage.setItem("loginStatus", "true");
  }
  if (pseudo_email) {
    window.localStorage.setItem("pseudo_email", pseudo_email);
  }
  if (email) {
    window.localStorage.setItem("email", email);
  }

  if (user_name) {
    window.localStorage.setItem("user_name", user_name);
  }
  if (is_admin) {
    window.localStorage.setItem("is_admin", is_admin === "True" ? true : false);
  }

  let gettype = (email) => {
    if (email.split("@")[1] === "redseerconsulting.com") {
      return "internal";
    } else {
      if (window.localStorage.getItem("clientID") == 141) {
        return "freemium";
      } else {
        return "premium";
      }
    }
  };

  if (
    `${process.env.REACT_APP_API_ENDPOINT}` ===
      "https://api.benchmarks.digital" &&
    window.localStorage.getItem("loginStatus") === "true"
  ) {
    browsee.init({ apiKey: `${process.env.REACT_APP_API_BROWSEE_KEY}` });
    if (email) {
      let val = gettype(email);
      browsee.identify(email, {
        email: email,
        name: val,
      });
    } else {
      let val = gettype(window.localStorage.getItem("email"));
      browsee.identify(window.localStorage.getItem("email"), {
        email: window.localStorage.getItem("email"),
        name: val,
      });

    }
  } else {
  }


  // console.log("Result",result[0])

  if (window.localStorage.getItem("loginStatus") !== "true") {
    if (source) {
      if (source === "newsletter") {
        if (source) window.localStorage.setItem("source", source);
        if (val) window.localStorage.setItem("newsletter_val", val);
        if (filter) window.localStorage.setItem("newsletter_filter", filter);
        if (filter)
          window.localStorage.setItem("newsletter_filter_val", filter);
        if (key) window.localStorage.setItem("newsletter_key", key);
        if (content_id) window.localStorage.setItem("content_id", content_id);
        if (content_type)
          window.localStorage.setItem("content_type", content_type);
        if (newsletter_name)
          window.localStorage.setItem("newsletter_name", newsletter_name);
        if (newsletter_month)
          window.localStorage.setItem("newsletter_month", newsletter_month);
        if (newsletter_user)
          window.localStorage.setItem("newsletter_user", newsletter_user);
        if (newsletter_email)
          window.localStorage.setItem("newsletter_email", newsletter_email);
        return <Navigate to="/otp/?source=newsletter" />;
      }
    } else {
      window.localStorage.setItem("is_external", true);
      return <Navigate to="/signin3" />;
    }
  }
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  if (!key && source === "newsletter") {
    if (newsletter_name)
      window.localStorage.setItem("newsletter_name", newsletter_name);
    if (newsletter_month)
      window.localStorage.setItem("newsletter_month", newsletter_month);
    if (newsletter_user)
      window.localStorage.setItem("newsletter_user", newsletter_user);
    if (newsletter_email)
      window.localStorage.setItem("newsletter_email", newsletter_email);
    if (source) window.localStorage.setItem("source", source);
    if (content_id) window.localStorage.setItem("content_id", content_id);
    if (content_type) window.localStorage.setItem("content_type", content_type);
    return <Navigate to="/redirect" />;
  } else {
    return (
      <div>
        <Header />
        <PowerBiFrame3
          initialOpenNodes={[init_node]}
          headarr={["Sectors"]}
          //  treeziparr={result[0]}
          root_rep={val}
          value={val}
          key={key}
          filter={filter !== "null" ? filter : null}
          filter_value={
            IndustryDict[key].filter_value !== "null"
              ? IndustryDict[key].filter_value
              : null
          }
          content_id={content_id}
          industry_id={IndustryDict[key].industry_id}
        />
      </div>
    );
  }
};

export default Report3;
