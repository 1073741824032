import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Link,
  useLocation,
  useRouteMatch,
  useNavigate,
} from "react-router-dom";
import UserCard from "./UserCard";
import SectorSignIn from "./Sectors";
import { media } from "../../utils/media";
import { IMAGES } from "../../constants/constants";
import HambergurMenu from "../svg/HambergurMenu";
import ArrowIcon from "../svg/ArrowIcon";
import MobileSideBar from "./MobileSideBar";
import { onLandScapePageOpenCT } from "../../utils/clevertap";
import axios from "axios";
import moment from "moment";
import { PARAMETER_GROUP_ENDPOINT } from "../../constants/constants";
import { onCompanyProfileClickCT } from "../../utils/clevertap";
import SearchBar from "./SearchBar";

const Header = () => {
  const location = useLocation();
  const [showMenuDetail, setShowMenuDetail] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [activeLink, setActiveLink] = useState(location.pathname);
  const userProfileRef = useRef(null);
  const userCardRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");

  const [compSearchString, setCompSearchString] = useState(null);
  const [allCompanies, setAllCompanies] = useState([]);
  const [visibleCompanies, setVisibleCompanies] = useState([]);
  const isLoggedIn = window.localStorage.getItem("loginStatus") === "true";
  const navigate = useNavigate();

  useEffect(() => {
    setActiveLink(location.pathname);
  }, []);

  let today = new Date();
  let hour = today.getHours();
  const handleSignOut = () => {
    window.localStorage.setItem("loginStatus", "false");
    window.localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userProfileRef.current &&
        !userProfileRef.current.contains(event.target) &&
        userCardRef.current &&
        !userCardRef.current.contains(event.target)
      ) {
        setShowUserProfile(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showUserProfile]);

  const stringContains = (target, query) => {
    const targetString = target.replace(/\s/g, "").toLowerCase();
    const queryString = query.replace(/\s/g, "").toLowerCase();
    return targetString.includes(queryString);
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      setCompSearchString(searchInput);
    }, 300); // Adjust debounce time as needed

    return () => clearTimeout(debounce); // Cleanup
  }, [searchInput]);

  useEffect(() => {
    const newFilteredCompanies = allCompanies.filter((comp) =>
      stringContains(comp.player_display_name, compSearchString)
    );

    setVisibleCompanies([...newFilteredCompanies]);
  }, [compSearchString]);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        PARAMETER_GROUP_ENDPOINT,
        {
          table_type: "iil_search_listing",
          industry_id: 40,
          profile: "overall",
          data_category: "overall",
          data_frequency: "monthly",
          end_date: moment(moment().startOf("month")).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;

          const industry_id_set = new Set();
          let industryDataList = [];
          let companiesData = responseData.players_list.map((comp) => {
            if (
              !industry_id_set.has(comp.industry_id) &&
              comp.player_id
              // && comp.company_profile_id in subDictionary
            ) {
              industry_id_set.add(comp.industry_id);
              industryDataList.push({
                company_id: comp.company_id,

                industry_id: comp.industry_id,
                player_name: comp.player_name,
                industry_name: comp.industry_name,
                selected: false,
              });
            }
            return {
              ...comp,
            };
          });

            let industriesData =responseData.industries_list.map((industry) => {
              return {
                ...industry,
                company_profile_id:industry.sector_profile_id,
                player_display_name:industry.industry_name
              };
            });

          const allData = [...companiesData, ...industriesData];

          setAllCompanies(allData);
        } else {
          console.error(
            "Error in fetching parameter group in landscape player listing ",
            response.error.message
          );
        }
      })
      .catch((error) => {
        console.error(
          "Error in fetching parameter group for landscape player listing -",
          error
        );
      });
  }, []);

  return (
    <div style={{ backgroundColor: "#FFFFFF" }}>
      <HeaderWrap>
        <NavBarStart>
          <MobileMenu
            isOpen={showMenuDetail}
            onIconClick={() => setShowMenuDetail(!showMenuDetail)}
          ></MobileMenu>
          <LogoDesktop
            src={IMAGES.logo_big}
            alt="Benchmarks"
            onClick={() => {
              navigate(isLoggedIn ? "/internet-landscape" : "/");
              onLandScapePageOpenCT();
            }}
          />
          {/* <LogoMobile
            src={IMAGES.logo_small}
            alt="Benchmarks"
            onClick={() => {
              navigate(isLoggedIn ? "/internet-landscape" : "/");
            }}
          /> */}
        </NavBarStart>
        <NavBarMid>
          {/* <NavLink
            isactive={activeLink === (isLoggedIn ? "/internet-landscape" : "/")}
            onClick={() => {
              navigate(isLoggedIn ? "/internet-landscape" : "/");
              onLandScapePageOpenCT();
            }}
          >
            Home
          </NavLink> */}

          {isLoggedIn ? (
            <SectorSignIn />
          ) : (
            <NavLink
              isactive={activeLink === "/industries"}
              onClick={() => navigate("/industries")}
            >
              Sectors
            </NavLink>
          )}
          <NavLink
            isactive={
              activeLink ===
              (isLoggedIn ? "/company-listing-new" : "/companies")
            }
            onClick={() =>
              navigate(isLoggedIn ? "/company-listing-new" : "/companies")
            }
          >
            Platforms
          </NavLink>
          {/* <NavLink
            isactive={activeLink === "/brands"}
            onClick={() => navigate("/brands")}
          >
            Brands
          </NavLink> */}

          {window.localStorage.getItem("email") && isLoggedIn &&
          ["redseerconsulting", "redcore", "agami"].some((domain) =>
            window.localStorage.getItem("email").split("@")[1]?.includes(domain)
          ) ? (
            <NavLink
              onClick={async () => {
                const email = window.localStorage.getItem("email");
                const response = await fetch(
                  `${
                    process.env.REACT_APP_AGAMI_API_ENDPOINT
                  }/auth/microsoft/login?source=benchmarks&email=${encodeURIComponent(
                    email
                  )}`
                );
                const data = await response.json();

                if (data.auth_url) {
                  window.open(data.auth_url, "_blank");
                } else {
                  console.error("Failed to get Microsoft auth URL");
                }
              }}
            >
              Ask AI{" "}
              <div style={{ paddingLeft: "5%", color: "red" }}>
                {" "}
                (&beta;eta)
              </div>
            </NavLink>
          ) : null}
          <SearchNavLink>
            {isLoggedIn ? (
              <>
                <CompanyInput
                  placeholder="Search"
                  type="search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                ></CompanyInput>
                <SearchBar
                  key={compSearchString}
                  visibleCompanies={visibleCompanies}
                />
              </>
            ) : null}
          </SearchNavLink>
        </NavBarMid>

        <NavBarMidSearch>
          <SearchNavLinkMobile>
            {isLoggedIn ? (
              <>
                <CompanyInputMobile
                  placeholder="Search"
                  type="search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                ></CompanyInputMobile>
                <SearchBar
                  key={compSearchString}
                  visibleCompanies={visibleCompanies}
                />
              </>
            ) : null}
          </SearchNavLinkMobile>
        </NavBarMidSearch>

        <NavBarEnd>
          {isLoggedIn ? (
            <ProfileWrapper>
              <Profile
                onClick={() => {
                  setShowUserProfile(!showUserProfile);
                }}
                ref={userProfileRef}
              >
                <GreetingMessageDesktop>
                  {hour < 17
                    ? hour < 12
                      ? "Good Morning "
                      : "Good Afternoon "
                    : "Good Evening "}
                  {window.localStorage.getItem("user_name") !== undefined
                    ? window.localStorage.getItem("user_name")
                    : window.localStorage.getItem("email").split("@")[0]}
                </GreetingMessageDesktop>

                <GreetingMessageMobile>
                  &nbsp;
                  {window.localStorage.getItem("user_name") !== undefined
                    ? window.localStorage.getItem("user_name")
                    : window.localStorage.getItem("email").split("@")[0]}
                </GreetingMessageMobile>
                <IconArrow
                  color="#A0A6A9"
                  rotate={showUserProfile ? 270 : 90}
                />
              </Profile>
              {showUserProfile && (
                <div
                  ref={userCardRef}
                  style={{
                    position: "relative",
                    display: "flex",
                    backgroundColor: "pink",
                  }}
                >
                  <UserCard onCloseProfile={() => setShowUserProfile(false)} />
                </div>
              )}
            </ProfileWrapper>
          ) : (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => (window.location.href = "/signin3")}
            >
              Sign in
            </div>
          )}
        </NavBarEnd>
        {showMenuDetail ? (
          <MobileSideBar
            isOpen={showMenuDetail}
            onCloseClick={() => {
              setShowMenuDetail(false);
            }}
          />
        ) : null}
      </HeaderWrap>
    </div>
  );
};

export default Header;

const NavBarMidSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const CompanyInputMobile = styled.input`
  width: 100%;
  height: 100%;
  padding: 14px 32px 14px 56px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  align-content: center;
  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  ::placeholder {
    color: #bfbfbf;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    ${media.small`
    font-size: 8px;
  `}
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }
  ${media.small`
  font-size: 15px;
  padding: 3px 8px 3px 14px;
`}
`;

const SearchNavLinkMobile = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 25px;
  align-items: center;
  display: none;
  white-space: nowrap;
  cursor: pointer;
  ${media.small`
    font-size: 12px;
    margin: 0px 2.5px;
    display:block;
  `}
`;

const HeaderWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3.5vw;
  padding-right: 3.5vw;
  border-bottom: 1px solid #c9c9c9;
  height: 7vh;
`;
const Dropbutton = styled.button`
  border-radius: 50%;
  border: 0px;
  background-color: white;
  outline: none !important;
  &:hover {
    background-color: #efefef;
  }
`;

const LogoDesktop = styled.img`
  width: 164px;
  height: 20px;
  cursor: pointer;
  ${media.small`
    width: 100px;
    height: 40px;
  `}
  font-size: 24px;
`;

const LogoMobile = styled.img`
  width: 32px;
  height: 32px;
  ${media.medium`
    display: none;
  `}
  ${media.large`
    display: none;
  `}
  ${media.xlarge`
    display: none;
  `}
  font-size: 24px;
`;

const SignOut = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  display: ${(props) => props.display};
  position: relative;
  flex-direction: column;
  top: 80px;
  left: -40px;
  align-items: center;
  justify-content: center;
  background: #f6f6f6;
  border-radius: 5px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.5);
  width: 120px;
  font-size: 16px;
  /* transition-duration:160ms; */
  text-align: center;
  div {
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
    &:hover {
      background-color: #ddd;
    }
  }
`;

const NavLink = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 25px;
  align-items: center;
  white-space: nowrap;
  color: ${(props) => (props.isactive ? "#0099FF" : "#000000")};
  cursor: pointer;
  &:hover {
    color: #0099ff;
  }
  ${media.small`
    font-size: 12px;
    margin: 0px 2.5px;
  `}
`;
const SearchNavLink = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 25px;
  align-items: center;

  white-space: nowrap;
  cursor: pointer;
  ${media.small`
    font-size: 12px;
    margin: 0px 2.5px;
  `}
`;

const NavBarStart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const NavBarMid = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  ${media.small`
    display: none;
  `}
`;

const NavBarEnd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const MobileMenu = styled(HambergurMenu)`
  margin-right: 10px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  ${media.medium`
    display: none;
  `}
  ${media.large`
    display: none;
  `}
  ${media.xlarge`
    display: none;
  `}
`;

const GreetingMessageDesktop = styled.span`
  font-size: 16px;
  font-weight: 500;
  ${media.small`
    display: none;
  `}
`;

const GreetingMessageMobile = styled.span`
  font-size: 16px;
  font-weight: 500;
  ${media.small`
    font-size: 14px;
  `}
  ${media.medium`
    display: none;
  `}
  ${media.large`
    display: none;
  `}
  ${media.xlarge`
    display: none;
  `}
`;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  height: 100%;
`;

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

const IconArrow = styled(ArrowIcon)`
  height: 20px;
  width: 20px;
  margin-left: 5px;
`;

const CompanyInput = styled.input`
  width: 85%;
  height: 100%;
  padding: 7px 8px 7px 28px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  align-content: center;
  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  ::placeholder {
    color: #bfbfbf;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    ${media.small`
    font-size: 9px;
  `}
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }
  ${media.small`
  font-size: 15px;
    width: 100%;

  padding: 7px 8px 7px 28px;
`}
${media.medium`
      width: 100%;

  `}
`;

const CpProfileLink = styled.div`
  color: ${(props) => (props.islink === "true" ? "#0099FF" : "#000000")};
  cursor: ${(props) => (props.islink === "true" ? "pointer" : "default")};
  :hover {
  }
  display: flex;
  white-space: nowrap;
  justify-content: flex-start;
  width: 100%;
  font-size: 18px;
  ${media.small`
font-size:12px;
`}
`;

const CountTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CountText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 10px;
  ${media.small`
  font-size: 12px;
  padding-left: 5px;
  line-height: 12px;

  `}
`;
const HeaderNameDiv = styled.div`
  font-size: 16px;

  ${media.small`
  font-size: 12px;
 

  `}
`;

const SectorProfile = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
`;

const PlayerWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const CompanyNameWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  // background: red;
  ${media.small`
  // justify-content: flex-start;
  `}
`;

const ColumnWarp = styled.div`
  display: flex;
  flex-direction: column;
  // background:blue;
`;
