import React, { useState, useEffect, useRef } from "react";
import "./dropdown.css";
import {
  GiBreakingChain,
  GiConsoleController,
  GiEvilFork,
  GiHealthNormal,
  GiCarWheel,
  GiClothes,
  GiMedicines,
  GiFruitBowl,
  GiVideoConference,
  GiHamburgerMenu,
} from "react-icons/gi";
import {
  FaAmazon,
  FaTrafficLight,
  FaUsers,
  FaDeezer,
  FaAlignLeft,
  FaKeyboard,
  FaCity,
} from "react-icons/fa";
import { MdKeyboardArrowDown } from "react-icons/md";
import styled from "styled-components";
import { media } from "../../utils/media";

function MyDropdown(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(!isOpen);
    // window.localStorage.setItem('dropdnOption', option.label)
    // console.log('op=', option)
    props.onOptionSelect(option);
    // setIsOpen(false);
  };

  let option1 = [
    { id: 1, name: "Option 1", icon: <GiHamburgerMenu /> },
    { id: 2, name: "Option 2", icon: <GiHamburgerMenu /> },
    { id: 3, name: "Option 3", icon: <GiHamburgerMenu /> },
  ];

  return (
    <DropDowndiv ref={dropdownRef}>
      {props.prev_value === null ? (
        <DropDownButton onClick={toggleDropdown}>
          {props.default_head}
          <MdKeyboardArrowDown />
        </DropDownButton>
      ) : (
        <DropDownButton onClick={toggleDropdown}>
          {props.prev_value}
          <MdKeyboardArrowDown />
        </DropDownButton>
      )}
      {isOpen ? (
        <Menu style={props.style}>
          {props.options.map((option) => (
            <DropDownOption
              key={option.label}
              center={!(option.icon && option.label)}
              onClick={() => handleOptionClick(option)}
            >
              {option.icon && <OptionIcon>{option.icon}</OptionIcon>}
              <OptionText>{option.label}</OptionText>
            </DropDownOption>
          ))}
        </Menu>
      ) : null}
    </DropDowndiv>
  );
}

export default MyDropdown;

const DropDowndiv = styled.div`
  position: relative;
  display: inline-block;

`;

const DropDownButton = styled.button`
  border: 1px solid #b9bec1;
  border-radius: 5px;
  background-color: #ffffff;
  color: black;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  ${media.small`
    border-radius:3px;
    border: 0.5px solid #b9bec1;
    font-size: 12px;
    padding: 5px;
  `}
`;

const DropDownOption = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.center ? "space-around" : "space-between"};
  align-items: center;
  cursor: pointer;
  margin: 2.5px 0px;
  ${media.small`
    margin: 2px 0px;
  `}
`;

const OptionIcon = styled.span`
  margin-right: 5px;
  ${media.small`
    margin-right: 3px;
  `}
`;

const OptionText = styled.span`
  font-size: 16px;
  color: #262e40;
  font-weight: 400;
  ${media.small`
    font-size: 12px;
`}
`;

const Menu = styled.ul`
  background-color: #ffffff;
  position: absolute;
  right: 0;
  list-style-type: none;
  padding: 5px;
  max-height: 180px;
  overflow-y: scroll;
  z-index:10;
  border-radius: 5px;
  border: 1px solid #b9bec1;
  min-width: 100%;
  ${media.small`
    max-height: 100px;
    border-radius: 3px;
    min-width: 100%;
  `}
`;
