import React, { useState, useEffect, useRef, Suspense } from "react";
import TreeMenu from "react-simple-tree-menu";
import styled from "styled-components";
import { TailSpin } from "react-loader-spinner";
import { ListGroupItem, ListGroup } from "reactstrap";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { Navigate, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import ArrowIcon from "../svg/ArrowIcon";
import DynamicComponent from "../../pages/DynamicComponent";
import DjangoEmbed from "../../pages/DjangoEmbed";
import Frontpage from "../../pages/Frontpage";
import Internet from "../../pages/Internet";
import Subscription from "../../pages/Subscription";
import Sub from "../../pages/Sub";
import MyDropdown from "../DropDown/dropdown";
import LinkDropDown from "../LinkDropDown/LinkDropDown";
import NewsletterSlides from "../NewsletterSlides/NewsletterSlides";
import CrossIcon from "../svg/CrossIcon";
import { media } from "../../utils/media";
import { BsGear, BsFillCloudArrowDownFill, BsTag } from "react-icons/bs";
import MyContext from "../../utils/contexts/MyContext";
import ArticlesList from "../ArticlesList";
import ArticleDetail from "../ArticleDetail";
import {
  ANALYSIS_ENDPOINT,
  CONTENT_ARTICLE_ENDPOINT,
  CONTENT_REPORT_ENDPOINT,
  REPORT_PAGES_DATA_ENDPOINT,
} from "../../constants/constants";
import axios from "axios";
import QueryFooter from "../QueryFooter/QueryFooter";
import { useSelector } from "react-redux";
import { selectnewreportaccessData } from "../../utils/redux/slices";
import { useDispatch } from "react-redux";
import { setNewReportAccessData } from "../../utils/redux/slices";
import { saveToDb } from "../../utils/redux/idb";
import { getFromDb } from "../../utils/redux/idb";
import Fintable from "../FinTable/Fintable";
import BeadGraph from "../BeadGraph";
import RelatedDataSets from "../RelatedDataSets";
import ReadyReckoner from "../ReadyReckoner";
import Testing from "../../pages/Testing";
import ArialStacked from "../ArialStacked";
import WaterFallWalletShare from "../WaterFallWalletShare/WaterFallWalletShare";
import Swot from "../Swot/Swot";
import {
  onQuickLinkClickCT,
  onCompanyProfileClickCT,
  onDownloadExcelClickCT,
  onSectorLoadMoreClickCT,
  onCPLoadMoreClickCT,
  onNewsletterClickCT,
  onReportSubscriptionRequestClickCT,
  onCurrencySwitchClickCT,
  onYearSwitchClickCT,
  onReadContentClickCT,
  onReportPageVisitCT,
} from "../../utils/clevertap";
import { Carousel } from "react-responsive-carousel";
import MyStackedBarChart from "../Bargraph/bars";
import PowerBiMain from "../PowerBiMain";
import RedseerAnalysisDetail from "../RedseerAnalysisDetail";
import RedseerAnalysisCard from "../RedseerAnalysisCard";
import ReportPages from "../ReportPages";
import FreemiumPlayerListing from "../../pages/InternetLandscape/FreemiumPlayerListing";
import ChangeLogComponent from "../ChangeLogs";
import MultiSelect from "../MultiSelect";
import { EXCHANGE_RATE_DETAIL_ENDPOINT } from "../../constants/constants";
import TabbedHighlights from "../HighLightTab";
import moment from "moment";

const PowerBiFrame3 = (parent_props) => {
  const [reportId, setReportId] = useState(null);
  const [reportPagesData, setReportPagesData] = useState([]);
  const [subReportId, setSubReportId] = useState(null);
  const [subReportPagesData, setSubReportPagesData] = useState([]);
  const [newReportPages, setnewReportPages] = useState([]);
  const [isPbPageAvailable, setPbPageAvailable] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [treearr, setTreearr] = useState([]);
  const [treeziparr, setTreeziparr] = useState([]);
  const [labelSelected, setLabelSelected] = useState(null);
  const [showLoader, setshowLoader] = useState(false);
  const [treemenucollapse, settreeMenuColapse] = useState(false);
  const [currencyval, setCurrencyVal] = useState(80);
  const [conversiontype, setConversionType] = useState("Custom");
  const [moneymodalIsOpen, setMoneyModalIsOpen] = useState(false);
  const [dropDownData, setDropDownData] = useState([]);
  const [filterarr, setfilterarr] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [moneyOption, setMoneyOption] = useState(null);
  const [yearOption, setYearOption] = useState(null);
  const [selectedpage, setSelectedPage] = useState("Consumer Internet");
  const [reportarr, setNewReportArr] = useState([]);
  const [showReport, setshowReport] = useState(false);
  const [isCurrentReportSubscribed, setCurrentReportSubscribed] =
    useState(false);
  const [filterVal, setFilterVal] = useState(null);
  const [showExcel, setShowExcel] = useState(false);
  const [excelLoader, setExcelLoader] = useState(false);
  const [showRedseerAnalysis, setShowRedseerAnalysis] = useState(false);
  const [redseerAnalysisPages, setRedseerAnalysisPages] = useState([]);
  const [isAnalysisLoading, setIsAnalysisLoading] = useState(false);
  const [redseerAnalysis, setRedseerAnalysis] = useState(null);
  const [section3Pages, setSection3Pages] = useState([]);
  const [sectorPfListing, setSectorPfListing] = useState(false);
  const [sectorPfListingFree, setSectorPfListingFree] = useState(false);
  const [companyDropdown, setCompanyDropdown] = useState(false);
  const [showSection5, setShowSection5] = useState(false);
  const [showSection6rev, setShowSection6rev] = useState(false);
  const [showSection7swot, setShowSection7swot] = useState(false);
  const [shownewsletter, setShownewsletter] = useState(false);
  const [showSectionReadyRec, setShowSectionReadyRec] = useState(false);
  const [showRelatedDataSet, setShowRelatedDataSet] = useState(false);
  const [showSectionIncome, setShowSectionIncome] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [showArticleDetail, setShowArticleDetail] = useState(false);
  const [showsub, setshowsub] = useState(false);
  const newrepaccessdata = useSelector(selectnewreportaccessData);
  const { setcurr_reportid, setreportplayers } = React.useContext(MyContext);
  const dispatch = useDispatch();
  const [companyList, setCompanyList] = useState(null);
  const [buttonState, setButtonState] = useState('default'); 
  const [subscribedReports, setsubscribedReports] = useState([]);

  const showIncomeStatement = useRef(false);
  const navigate = useNavigate();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const newsletter_name =
    useQuery().get("newsletter_name") ||
    window.localStorage.getItem("newsletter_name") ||
    "";
  const newsletter_month =
    useQuery().get("newsletter_month") ||
    window.localStorage.getItem("newsletter_month") ||
    "";
  const newsletter_user =
    useQuery().get("newsletter_user") ||
    window.localStorage.getItem("newsletter_user") ||
    "";
  const newsletter_email =
    useQuery().get("newsletter_email") ||
    window.localStorage.getItem("newsletter_email") ||
    "";
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  const key = new URLSearchParams(useLocation().search).get("key");
  const url_val = useQuery().get("val");
  const url_industry_id = useQuery().get("industry_id");
  const url_key = useQuery().get("key");
  const url_filter = useQuery().get("filter");
  const url_filter_value = useQuery().get("filter_value");
  const url_source =
    useQuery().get("source") || window.localStorage.getItem("source");
  const url_content_id =
    useQuery().get("content_id") || window.localStorage.getItem("content_id");
  const url_content_type =
    useQuery().get("content_type") ||
    window.localStorage.getItem("content_type");
  const [pageLoaded, setPageLoaded] = useState(false);
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [colorMappedPlayers,setColorMappedPlayers]=useState({});
  let newReportTree = JSON.parse(window.localStorage.getItem("newReporTree"));
  const is_athena=process.env.REACT_APP_API_ENDPOINT.includes("athena");

  const setSelectedCompany = (selectedIndustry) => {
    setCompanyList(
      companyList.map((ind) => {
        return {
          ...ind,
          selected: selectedIndustry.some(
            (selectedInd) => selectedInd.name === ind.name
          ),
        };
      })
    );
    const url = `/Report3/?val=${selectedIndustry[0].report_name}&key=${selectedIndustry[0].id}&filter=${selectedIndustry[0].filter}&filter_value=${selectedIndustry[0].filter_value}`;
    window.location.assign(url);
  };

  function findParents(data, label, key_val, parents = []) {
    for (let node of data) {
      // If the current node matches the given label and key_val
      if (
        node.report_name.toLowerCase() === label.toLowerCase() &&
        node.id === key_val
      ) {
        // Add the current node's label and key to the parents array
        parents.push([
          node.report_name + " ",
          node.id,
          node.filter_value,
          node.filter,
        ]);
        return parents; // return the parents including current node
      }

      // If the current node has children, recurse
      if (node.children && node.children.length) {
        const newParents = [
          ...parents,
          [node.report_name + " ", node.id, node.filter_value, node.filter],
        ];
        const found = findParents(node.children, label, key_val, newParents);
        if (found) return found;
      }
    }
    return null; // If not found
  }

  function groupLevel5NodesByParentLevel3(data, url_key) {
    const result = [];

    function traverse(nodes) {
      nodes.forEach((node) => {
        if (
          node.level === 3 &&
          node.id === url_key &&
          node.children &&
          node.children.length > 0
        ) {
          node.children.forEach((child) => {
            if (
              child.level === 4 &&
              child.children &&
              child.children.length > 0
            ) {
              result.push(
                ...child.children.map((level5Node) => ({
                  ...level5Node,
                  selected: false,
                  name: level5Node.report_name,
                }))
              );
            }
          });
        }
        if (node.children && node.children.length > 0) {
          traverse(node.children);
        }
      });
    }

    traverse(data);

    return result;
  }
useEffect(()=>{

  fetch(`${process.env.REACT_APP_API_ENDPOINT}/expiry-date-client/?client_id=${window.localStorage.getItem("clientID")}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((res) => res.json())
  .then((res) => {

    if (!res.expiry_date) {
      console.log("No expiry date provided, session is valid.");
    } else {
      const expiryDate = new Date(res.expiry_date); 
      const currentDate = new Date(); 
    
      expiryDate.setHours(0, 0, 0, 0);
      currentDate.setHours(0, 0, 0, 0);

      if (currentDate > expiryDate) {
        console.log("Session expired, logging out...");
        handleSignOut(); 
      } else {
        console.log("Session is still valid.");
      }
    }
    
  }).catch((error) => {
    console.error("Error in fetching expiry date of client", error);

  });

  window.localStorage.setItem("lastAccessed", key);
  if(parent_props.industry_id)
  {
  fetch(
    `${process.env.REACT_APP_WEBFORM_ENDPOINT}/sector-players/?industry_id=${parent_props.industry_id}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((res) => res.json())
    .then((res) => {
      const players = res.data;

      const myColors = [
        "#0099FF",
        "#A962FF", 
        "#26CDCD", 
        "#9FC51E", 
        "#FF832B",
        "#FCBB53",
        "#21AF4A",
        "#66C2FF",
        "#4673FF",
        "#F28D8C",
        "#7D7DE8",
        "#AFEEEE",
        "#DA70D6", 
        "#98FB98", 
        "#F58CBD",
        "#FFDD44", 
        "#89CFF0",
        "#FF6F61",
        "#B1EDE8",
        "#C9A0DC",
        "#93DFB8",
        "#FFD700",
        "#F4A460",
        "#ADD8E6",
        "#FFB347",
        "#77DD77",
        "#DDA0DD",
        "#FFC0CB",
        "#FF6961",
        "#B39EB5",
        "#FFB6C1",
      ];

      // Create the color mapping
      const colorMapping = players.reduce((acc, player, index) => {
        acc[player.player_name] = myColors[index % myColors.length];
        return acc;
      }, {});
      setColorMappedPlayers(colorMapping)
    });
  }

  fetch(`${process.env.REACT_APP_API_ENDPOINT}/new-report-api-temp/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      window.localStorage.setItem(
        "newReporTree",
        JSON.stringify(data[0].children)
      );

      function extractIndutryIds(newReportTree) {
        const idDictionary = {};

        function transverse(newReportTree) {
          newReportTree.forEach((node) => {
            if (node.level === 3 || node.level === 5) {
              idDictionary[node.id] = node
            }
            if (node.children && node.children.length > 0)
              transverse(node.children);
          });
        }

        transverse(newReportTree);

        return idDictionary;
      }
      window.localStorage.setItem(
        "IndustryDict",
        JSON.stringify(extractIndutryIds(data[0].children))
      );
    })
    .catch((error) => console.error("Error fetching tree data:", error));

  axios
  .get(`${EXCHANGE_RATE_DETAIL_ENDPOINT}`)
  .then((response) => response.data)
  .then((data) => {
    if (data.status === 200) {
      window.localStorage.setItem(
        "EXCHANGE_RATE",
        JSON.stringify((data.data))
      );
    }
  })
  .catch((error) => {
    console.error("Error in fetching exchange rate-", error);
  });
  const subDictionary = {};

  fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/sub-data/?client_id=` +
      window.localStorage.getItem("clientID"),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => {
      data.forEach((item) => {
        subDictionary[item["Report Id"]] = item;
      });
      window.localStorage.setItem(
        "subDictionary",
        JSON.stringify(subDictionary)
      );
    })
    .catch((error) =>
      console.error("Error fetching subscriber data: ", error)
    );
   
  setIsAnalysisLoading(true);
  axios
    .get(`${ANALYSIS_ENDPOINT}/${parent_props.initialOpenNodes[0]}/`)
    .then((response) => response.data)
    .then((analysis) => {
      if (analysis.status === 200) {
        setRedseerAnalysis(analysis.data);
      }
      setIsAnalysisLoading(false);
    })
    .catch((error) => {
      console.error("Error in fetching analysis-", error);
      setIsAnalysisLoading(false);
    });

    let client_id = window.localStorage.getItem("clientID");
    let rep_id = parent_props.initialOpenNodes[0];
    let prop_token = window.localStorage.getItem("token");
    setshowLoader(true);
    const result = findParents(
      newReportTree,
      IndustryDict[url_key].report_name,
      parseInt(rep_id)
    );

    if (result) {
      result.unshift(["Sectors ", 9, null, null]);
      setTreeziparr(result);
    }

    let filterValue = parent_props.filter_value
      ? parent_props.filter_value.replace(/\band\b/g, "&")
      : null;
    setSelectedPage(IndustryDict[url_key].report_name);
    setFilterVal(filterValue);
    if (parent_props.filter) setfilterarr(parent_props.filter.split(","));
    if (newrepaccessdata.length > 0) {
      let res = newrepaccessdata;
      setNewReportArr(res);
      let rep_arr = res;
      handleClickTree(parent_props.value, rep_id, true, -1, rep_id, rep_arr);
    } else {
      const getdatafromdb = async () => {
        let storedData = [];
        try {
          storedData = await getFromDb("newreportaccessdata");
        } catch {}
        if (storedData.length > 0) {
          dispatch(setNewReportAccessData(storedData));
          let res = storedData;
          setNewReportArr(res);
          let rep_arr = res;
          handleClickTree(
            parent_props.value,
            rep_id,
            true,
            -1,
            rep_id,
            rep_arr
          );
        } else {
          fetch(
            `${process.env.REACT_APP_API_ENDPOINT}/newreportaccess/?client_id=${client_id}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then((res) => {
              dispatch(setNewReportAccessData(res));
              saveToDb("newreportaccessdata", res);
              setNewReportArr(res);
              let rep_arr = res;
              handleClickTree(
                parent_props.value,
                rep_id,
                true,
                -1,
                rep_id,
                rep_arr
              );
            });
        }
      };
      getdatafromdb();
    }

    setCompanyList(
      groupLevel5NodesByParentLevel3(newReportTree, Number(url_key))
    );

    const timeoutId = setTimeout(() => {
      if (
        newsletter_name &&
        newsletter_month &&
        newsletter_user &&
        newsletter_email
      ) {
        onNewsletterClickCT(
          newsletter_name,
          newsletter_month,
          "clicked",
          newsletter_email,
          "Sector Report Page"
        );
        window.localStorage.removeItem("newsletter_name");
        window.localStorage.removeItem("newsletter_month");
        window.localStorage.removeItem("newsletter_user");
        window.localStorage.removeItem("newsletter_email");
      }
    }, 1000);
    if (url_val && url_key) {
      navigate(
        `/Report3/?val=${url_val}&key=${url_key}`
      );
    }
    if (url_content_id && url_content_type) {
      const content_url = `${
        url_content_type === "article"
          ? CONTENT_ARTICLE_ENDPOINT
          : CONTENT_REPORT_ENDPOINT
      }/${url_content_id}`;
      const content_type_name = `${
        url_content_type === "article" ? "Article" : "Report"
      }`;
      const fetchPageData = async (page) => {
        try {
          const response = await axios.get(content_url);
          const docResponse = response.data;
          setSelectedArticle({
            type: url_content_type,
            typeName: content_type_name,
            data: docResponse,
          });
        } catch (error) {
          console(error);
        }
      };
      fetchPageData();
      window.localStorage.removeItem("source");
      window.localStorage.removeItem("content_id");
      window.localStorage.removeItem("content_type");
    }
    return () => clearTimeout(timeoutId);

},[])


  useEffect(() => {
    if (showLoader === false) {
      const showCPProfile = () => {
        const companyProfileData = JSON.parse(
          window.localStorage.getItem("company_profile_data")
        );
        if (companyProfileData) {
          onPlayerClick(companyProfileData);
          window.localStorage.removeItem("company_profile_data");
        }
      };
      const timeoutId = setTimeout(() => {
        showCPProfile();
      }, 400);
      return () => clearTimeout(timeoutId);
    }
  }, [showLoader]);


  useEffect(() => {
    if (selectedArticle) {
      setShowArticleDetail(true);
    }
  }, [selectedArticle]);

  let getNodeName = (key, all_nodes) => {
    let nodes = all_nodes;
    for (let i = 0; i < nodes.length; i++) {
      // search in top layer
      if (nodes[i].key == key) {
        // //console.log('lognode=',nodes[i])
        return nodes[i].label;
      } else {
        if (nodes[i].nodes.length > 0) {
          let node_name = getNodeName(key, nodes[i].nodes);
          if (node_name) {
            return node_name;
          }
        } else {
          //console.log('node array was empty')
        }
      }
    }
    // return labels correponding to key
  };

  let handleSignOut = () => {
    localStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    if (reportId) {
      let client_id = window.localStorage.getItem("clientID");
      axios
        .get(
          `${REPORT_PAGES_DATA_ENDPOINT}/?report_id=${reportId}&client_id=${client_id}`
        )
        .then((response) => response.data)
        .then((response) => {
          if (response.status === 200) {
            setReportPagesData(response.data);
          } else {
            console.error(
              `Oops! Error in report pages data - ${response.error}`
            );
          }
        })
        .catch((error) => {
          console.error("Error in report pages data -", error);
        });
    }
  }, [reportId]);


  
  useEffect(() => {
    if (subReportId) {
      let client_id = window.localStorage.getItem("clientID");
      axios
        .get(
          `${REPORT_PAGES_DATA_ENDPOINT}/?report_id=${subReportId}&client_id=${client_id}`
        )
        .then((response) => response.data)
        .then((response) => {
          if (response.status === 200) {
            setSubReportPagesData(response.data);
          } else {
            console.error(
              `Oops! Error in sub-report pages data - ${response.error}`
            );
          }
        })
        .catch((error) => {
          console.error("Error in sub-report pages data -", error);
        });
    }
  }, [subReportId]);

  let handleClickTree = (
    reportname,
    key,
    finalized,
    index = -1,
    key_id = -1,
    report_arr = reportarr
  ) => {
    if (key === -1) {
      setSubReportId(Number(key_id));
      setSubReportPagesData([]);
    } else {
      setReportId(Number(key));
      setSubReportId(null);
      setSubReportPagesData([]);
    }
    window.reports = [];
    if (showArticleDetail) {
      setShowArticleDetail(false);
      setSelectedArticle(null);
    }

    if (showRedseerAnalysis === true) {
      setShowRedseerAnalysis(false);
    }

    window.localStorage.setItem("report", reportname);
    if ((index === treearr.length - 1) & (key === -2)) {
      // disable last click. also disable clicks on non links
      return;
    }
    if ((key === -2) & [].includes(reportname)) {
      return;
    }
    if (index === 0) {
      return;
    }
    if (index === 1) {
      if ([25, 72, 67, 95, 280].includes(parseInt(key_id))) {
        return;
      }
    }

    if (key_id == 9) {
      // update to take from backend
      window.localStorage.setItem("report", reportname);
      setTreeziparr([]);
      setSelectedPage(reportname);
      setshowReport(false);
      return;
    }
    let found = false;
    for (let i = 0; i < report_arr.length; i++) {
      if (report_arr[i].report_name === reportname) {
        window.localStorage.setItem("start_date", report_arr[i].start_date);
        window.localStorage.setItem("end_date", report_arr[i].end_date);
        setShowExcel(report_arr[i].excel_access);

        found = true;
        break;
      }
    }
    if (found === false) {
      setShowExcel(false);
      window.localStorage.setItem("start_date", null);
      window.localStorage.setItem("end_date", null);
    }
    setshowLoader(true);

    if (key === -1) {
      window.localStorage.setItem("report", reportname);
      setSelectedPage(reportname);
      if (finalized === true) {
        window.localStorage.setItem("finalized", "true");
      } else {
        window.localStorage.setItem("finalized", "false");
      }
    }
    if (key === -2) {
      window.localStorage.setItem("report", reportname);
      setSelectedPage(reportname);
      setSelectedOption(null);
      setfilterarr(["1"]);
      // check where the clicked report lies in array if it lies in range we show dropdown
      for (let i = 0; i < treearr.length; i++) {
        if (treearr[i] === reportname) {
          break;
        }
      }

      if (true) {
        let arr = treearr;
        for (let i = treearr.length - 1; i > index; i--) {
          arr.pop();
        }
        setTreearr(arr);
        let zip_arr = treeziparr;
        for (let i = treeziparr.length - 1; i > index; i--) {
          zip_arr.pop();
        }
        setTreeziparr(zip_arr);
      }
    }
    let prop_token = window.localStorage.getItem("token");
    let rep_id = parseInt(key_id);
    setcurr_reportid(rep_id);
    setTokenLoaded(false);
    let tokenApiComplete = false;
    fetch(
      `${
        process.env.REACT_APP_API_ENDPOINT
      }/clientreportpagestoken/?rep_id=${rep_id}&client_id=${window.localStorage.getItem(
        "clientID"
      )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${prop_token}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 401 || res.status === 500) {
          handleSignOut();
        }
        return res.json();
      })
      .then((res) => {
        tokenApiComplete = true;
        setTokenLoaded(true);
        setPageLoaded(true);
        let section1Data = [];
        if (res.length < 1) {
          setshowReport(false);
          setCurrentReportSubscribed(false);
          setPbPageAvailable(false);
        } else {
          if (res[res.length - 1]["link"] === "Sub") {
            res.pop();
            setshowsub(true);
          } else {
            setshowsub(false);
          }
          section1Data = res.filter((val) => val.section_type === "time_trend");
          setnewReportPages(section1Data);
          if (
            section1Data.filter((val) => val.page_type === "powerbi").length
          ) {
            setPbPageAvailable(true);
          } else {
            setPbPageAvailable(false);
          }
          const subData = res.filter((val) => val.page_name === "Sub").length;
          setCurrentReportSubscribed(subData > 0 ? false : true);
          setshowReport(true);
        }

        let analysisPages = res.filter(
          (val) => val.section_type === "redseer_analysis"
        );
        let sec3pages = res.filter((val) => val.section_type === "section 3");
        let sector_pf_listing = res.filter(
          (val) => val.section_type === "sector_pf_listing"
        );
        let sec5pages = res.filter((val) => val.section_type === "section 5");
        let sec6pages = res.filter((val) => val.section_type === "section 6");
        let sec7pages = res.filter((val) => val.section_type === "section 7");
        let newsletter = res.filter((val) => val.section_type === "newsletter");
        let sector_pf_listing_free = res.filter(
          (val) => val.section_type === "sector_pf_listing_free"
        );

        let company_dropdown = res.filter(
          (val) => val.section_type === "company_dropdown"
        );

        let IncomePages = res.filter(
          (val) => val.section_type === "income_pages"
        );
        let readyReckonerPages = res.filter(
          (val) => val.section_type === "ready_reckoner"
        );
        let relatedDataSetPage = res.filter(
          (val) => val.section_type === "related_data_set"
        );
        let IncomeStatementPages = res.filter(
          (val) => val.section_type === "income_statement"
        );
        if (sec3pages.length > 0) {
          setSection3Pages(sec3pages);
        }
        IncomePages.length > 0
          ? setShowSectionIncome(true)
          : setShowSectionIncome(false);
        if (sector_pf_listing.length > 0) {
          setSectorPfListing(true);
        } else {
          setSectorPfListing(false);
        }
        if (sector_pf_listing_free.length > 0) {
          setSectorPfListingFree(true);
        } else {
          setSectorPfListingFree(false);
        }

        if (company_dropdown.length > 0) {
          setCompanyDropdown(true);
        } else {
          setCompanyDropdown(false);
        }
        if (sec5pages.length > 0) {
          setShowSection5(true);
        } else {
          setShowSection5(false);
        }

        if (sec6pages.length > 0) {
          setShowSection6rev(true);
        } else {
          setShowSection6rev(false);
        }

        if (sec7pages.length > 0) {
          setShowSection7swot(true);
        } else {
          setShowSection7swot(false);
        }

        if (newsletter.length > 0) {
          setShownewsletter(true);
        } else {
          setShownewsletter(false);
        }
        if (readyReckonerPages.length > 0) {
          setShowSectionReadyRec(true);
        } else {
          setShowSectionReadyRec(false);
        }
        if (relatedDataSetPage.length > 0) {
          setShowRelatedDataSet(true);
        } else {
          setShowRelatedDataSet(false);
        }
        if (IncomeStatementPages.length > 0) {
          showIncomeStatement.current = true;
        } else {
          showIncomeStatement.current = false;
        }

        setRedseerAnalysisPages(analysisPages);
        setshowLoader(false);
      })
      .catch((error) => {
        // handleSignOut()
      });

    //2nd time new api

    //if(tokenLoaded)
    //   {
    fetch(
      `${
        process.env.REACT_APP_API_ENDPOINT
      }/clientreportpages/?rep_id=${rep_id}&client_id=${window.localStorage.getItem(
        "clientID"
      )}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${prop_token}`,
        },
      }
    )
      .then((res) => {
        if (res.status === 401 || res.status === 500) {
          handleSignOut();
        }
        return res.json();
      })
      .then((res) => {
        if (tokenApiComplete) return;
        let modifiedData = res.map((obj) => ({
          ...obj,
          report: obj.report_id,
        }));

        let section1Data = [];
        if (modifiedData.length < 1) {
          setshowReport(false);
          setCurrentReportSubscribed(false);
          setPbPageAvailable(false);
        } else {
          if (modifiedData[modifiedData.length - 1]["link"] === "Sub") {
            // modifiedData.pop();
            setshowsub(true);
          } else {
            setshowsub(false);
          }

          section1Data = modifiedData.filter(
            (val) => val.section_type === "time_trend"
          );
          setnewReportPages(section1Data);
          if (
            section1Data.filter((val) => val.page_type === "powerbi").length
          ) {
            setPbPageAvailable(true);
          } else {
            setPbPageAvailable(false);
          }
          const subData = modifiedData.filter(
            (val) => val.page_name === "Sub"
          ).length;
          setCurrentReportSubscribed(subData > 0 ? false : true);
          setshowReport(true);
        }

        let analysisPages = modifiedData.filter(
          (val) => val.section_type === "redseer_analysis"
        );
        let sec3pages = modifiedData.filter(
          (val) => val.section_type === "section 3"
        );
        let sector_pf_listing = modifiedData.filter(
          (val) => val.section_type === "sector_pf_listing"
        );

        let sector_pf_listing_free = modifiedData.filter(
          (val) => val.section_type === "sector_pf_listing_free"
        );

        let company_dropdown = modifiedData.filter(
          (val) => val.section_type === "company_dropdown"
        );

        let sec5pages = modifiedData.filter(
          (val) => val.section_type === "section 5"
        );
        let sec6pages = modifiedData.filter(
          (val) => val.section_type === "section 6"
        );
        let sec7pages = modifiedData.filter(
          (val) => val.section_type === "section 7"
        );

        let newsletter = modifiedData.filter(
          (val) => val.section_type === "newsletter"
        );
        let IncomePages = modifiedData.filter(
          (val) => val.section_type === "income_pages"
        );
        let readyReckonerPages = modifiedData.filter(
          (val) => val.section_type === "ready_reckoner"
        );
        let relatedDataSetPage = modifiedData.filter(
          (val) => val.section_type === "related_data_set"
        );
        let IncomeStatementPages = modifiedData.filter(
          (val) => val.section_type === "income_statement"
        );
        if (sec3pages.length > 0) {
          setSection3Pages(sec3pages);
        }
        IncomePages.length > 0
          ? setShowSectionIncome(true)
          : setShowSectionIncome(false);
        if (sector_pf_listing.length > 0) {
          setSectorPfListing(true);
        } else {
          setSectorPfListing(false);
        }
        if (sector_pf_listing_free.length > 0) {
          setSectorPfListingFree(true);
        } else {
          setSectorPfListingFree(false);
        }
        if (company_dropdown.length > 0) {
          setCompanyDropdown(true);
        } else {
          setCompanyDropdown(false);
        }

        if (sec5pages.length > 0) {
          setShowSection5(true);
        } else {
          setShowSection5(false);
        }

        if (sec6pages.length > 0) {
          setShowSection6rev(true);
        } else {
          setShowSection6rev(false);
        }

        if (sec7pages.length > 0) {
          setShowSection7swot(true);
        } else {
          setShowSection7swot(false);
        }
        if (newsletter.length > 0) {
          setShownewsletter(true);
        } else {
          setShownewsletter(false);
        }

        if (readyReckonerPages.length > 0) {
          setShowSectionReadyRec(true);
        } else {
          setShowSectionReadyRec(false);
        }
        if (relatedDataSetPage.length > 0) {
          setShowRelatedDataSet(true);
        } else {
          setShowRelatedDataSet(false);
        }
        if (IncomeStatementPages.length > 0) {
          showIncomeStatement.current = true;
        } else {
          showIncomeStatement.current = false;
        }
        setRedseerAnalysisPages(analysisPages);
        setshowLoader(false);
      })
      .catch((error) => {
        // handleSignOut()
      });

    //}

    //console.log(reportname)
  };

  let handleLoadMore = () => {
    setLoadMore(!loadMore);
    // for (let i = 0; i < window.reports.length; i++) {
    //   window.reports[i].embed_report.getActivePage().then((activePage) => {
    //     let active_ht = activePage.defaultSize.height;
    //     let active_width = activePage.defaultSize.width;
    //     let new_height = (active_ht / active_width) * window.innerWidth;
    //     document.getElementsByClassName(
    //       "report-style-class-newreport" + window.reports[i].key
    //     )[0].style.height = new_height + "px";
    //     document.getElementsByClassName(
    //       "report-style-class-newreport" + window.reports[i].key
    //     )[0].style.width = window.innerWidth + "px";
    //   });
    // }

    // CLEVERTAP*****
    if (!loadMore) {
      const currReportId = window.localStorage.getItem("rep_id");
      const currReportName = window.localStorage.getItem("report");
      if (selectedOption) {
        const selectedCP = platform_option.filter(
          (opt) => opt.label === selectedOption
        )?.[0];
        onCPLoadMoreClickCT(
          selectedCP.label,
          selectedCP.key,
          currReportName,
          currReportId,
          isCurrentReportSubscribed
        );
      } else {
        onSectorLoadMoreClickCT(
          currReportName,
          currReportId,
          isCurrentReportSubscribed
        );
      }
    }
    // *****CLEVERTAP
  };

  // CLEVERTAP*****
  const onSubmitResultToCT = (submitted) => {
    const currReportId = window.localStorage.getItem("rep_id");
    const currReportName = window.localStorage.getItem("report");
    if (selectedOption) {
      const selectedCP = platform_option.filter(
        (opt) => opt.label === selectedOption
      )?.[0];
      onReportSubscriptionRequestClickCT(
        submitted,
        currReportName,
        currReportId,
        "Company Profile",
        selectedCP.label,
        selectedCP.key
      );
    } else {
      onReportSubscriptionRequestClickCT(
        submitted,
        currReportName,
        currReportId,
        "Sector Profile"
      );
    }
  };
  const onMouseEnterPage = () => {
    // setPageEnterTime(new Date());
  };
  const onMouseLeavePage = (page_name, page_type, section_type) => {
    // if (pageEnterTime) {
    //   const pageLeftTime = new Date();
    //   const pageSpentTime = pageLeftTime - pageEnterTime;
    //   setPageEnterTime(null);
    //   const currReportId = window.localStorage.getItem("url_key");
    //   const currReportName = window.localStorage.getItem("report");
    //   if (selectedOption) {
    //     const selectedCP = platform_option.filter(
    //       (opt) => opt.label === selectedOption
    //     )?.[0];
    //     onReportPageVisitCT(
    //       page_name,
    //       page_type,
    //       section_type || "section 1",
    //       pageSpentTime,
    //       currReportName,
    //       currReportId,
    //       "Company Profile",
    //       selectedCP.label,
    //       selectedCP.key
    //     );
    //   } else {
    //     onReportPageVisitCT(
    //       page_name,
    //       page_type,
    //       section_type || "section 1",
    //       pageSpentTime,
    //       currReportName,
    //       currReportId,
    //       "Sector Profile"
    //     );
    //   }
    // }
  };
  // *****CLEVERTAP

  // const fintableSelect = (label, key_val) => {
  //   console.log(label);
  //   let treeziparr = window.localStorage.getItem("treeziparr");
  //   treeziparr?.push([label, key_val]);
  //   window.localStorage.setItem("treeziparr", treeziparr);
  // };

  const onPlayerClick = (option) => {
    window.localStorage.setItem("player_id", option.player_id);
    setFilterVal(option.filter_value);
    if (option.filter != null) {
      setfilterarr(option.filter.split(","));
    }
    treeziparr?.push([option.label, option.key_val]);
    setSelectedOption(option.label);
    handleClickTree(option.label, -1, option.finalized, -1, option.key_val);
  };

  const onYearSelect = (option) => {
    // CLEVERTAP*****
    const currReportId = window.localStorage.getItem("rep_id");
    const currReportName = window.localStorage.getItem("report");
    if (selectedOption) {
      const selectedCP = platform_option.filter(
        (opt) => opt.label === selectedOption
      )?.[0];
      onYearSwitchClickCT(
        option.label,
        currReportName,
        currReportId,
        "Company Profile",
        selectedCP.label,
        selectedCP.key
      );
    } else {
      onYearSwitchClickCT(
        option.label,
        currReportName,
        currReportId,
        "Sector Profile"
      );
    }
    // *****CLEVERTAP
    setYearOption(option.label);
    if (option.label === "CY") {
      window.localStorage.setItem("year", "CY");
    } else {
      window.localStorage.setItem("year", "FY");
    }

    const year_converter = {
      $schema: "http://powerbi.com/product/schema#advanced",
      target: {
        table: "Date Parameter",
        column: "Year_Type",
      },
      filterType: models.FilterType.Advanced,
      logicalOperator: "Is",
      conditions: [
        {
          operator: "Is",
          value: window.localStorage.getItem("year"),
        },
      ],
    };
    // required
    for (let i = 0; i < window.reports.length; i++) {
      window.reports[i].embed_report.getActivePage().then((activePage) => {
        activePage.getVisuals().then((visuals) => {
          let slicers = visuals.filter(function (visual) {
            return visual.type === "slicer";
          });
          slicers.forEach(async (slicer) => {
            const state = await slicer.getSlicerState();

            if (state.targets[0].column === "Year_Type") {
              let target_slicer = visuals.filter(function (visual) {
                return visual.type === "slicer" && visual.name === slicer.name;
              })[0];
              await target_slicer.setSlicerState({ filters: [year_converter] });
            }
          });
        });
      });
    }
  };

  const onMoneySelect = (option) => {
    // CLEVERTAP*****
    const currReportId = window.localStorage.getItem("rep_id");
    const currReportName = window.localStorage.getItem("report");
    if (selectedOption) {
      const selectedCP = platform_option.filter(
        (opt) => opt.label === selectedOption
      )?.[0];
      onCurrencySwitchClickCT(
        option.label,
        currReportName,
        currReportId,
        "Company Profile",
        selectedCP.label,
        selectedCP.key
      );
    } else {
      onCurrencySwitchClickCT(
        option.label,
        currReportName,
        currReportId,
        "Sector Profile"
      );
    }
    // *****CLEVERTAP
    setMoneyOption(option.label);
    if (option.label === "INR") {
      window.localStorage.setItem("currency", "INR");
    } else {
      window.localStorage.setItem("currency", "USD");
      window.localStorage.setItem("conversion_type", "Custom");
      window.localStorage.setItem("currency_val", currencyval);
    }

    const money_converter = {
      $schema: "http://powerbi.com/product/schema#advanced",
      target: {
        table: "Currency Table",
        column: "Currency",
      },
      filterType: models.FilterType.Advanced,
      logicalOperator: "Is",
      conditions: [
        {
          operator: "Is",
          value: window.localStorage.getItem("currency"),
        },
      ],
    };
    // required
    for (let i = 0; i < window.reports.length; i++) {
      window.reports[i].embed_report.getActivePage().then((activePage) => {
        activePage.getVisuals().then((visuals) => {
          let slicers = visuals.filter(function (visual) {
            return visual.type === "slicer";
          });
          slicers.forEach(async (slicer) => {
            const state = await slicer.getSlicerState();

            if (state.targets[0].column === "Currency") {
              let target_slicer = visuals.filter(function (visual) {
                return visual.type === "slicer" && visual.name === slicer.name;
              })[0];
              await target_slicer.setSlicerState({
                filters: [money_converter],
              });
            }
          });
        });
      });
    }
  };

  let handleGearClick = () => {
    let val = window.localStorage.getItem("currency_val");
    let conversion_type = window.localStorage.getItem("conversion_type");
    if (conversion_type !== null) {
      setConversionType(conversion_type);
    } else {
      setConversionType("Custom");
    }
    if (val !== null) {
      setCurrencyVal(val);
    } else {
      setCurrencyVal(80);
    }
    setMoneyModalIsOpen(true);
  };

  let incCurrency = () => {
    let new_curr = parseInt(currencyval, 10) + 1;
    if (new_curr > 100) {
      new_curr = 100;
    }
    setCurrencyVal(new_curr);
  };
  let decCurrency = () => {
    let new_curr = parseInt(currencyval, 10) - 1;
    if (new_curr < 65) {
      new_curr = 65;
    }
    setCurrencyVal(new_curr);
  };

  let onCurrencyModalSubmit = () => {
    if (conversiontype === "Custom") {
      window.localStorage.setItem("conversion_type", "Custom");
      window.localStorage.setItem("currency_val", currencyval);
      const currency_valuation = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "Currency input",
          column: "Currency input",
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Is",
        conditions: [
          {
            operator: "Is",
            value: currencyval,
          },
        ],
      };
      const usd_selector = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "Currency_USD_Type",
          column: "Type",
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Is",
        conditions: [
          {
            operator: "Is",
            value: conversiontype,
          },
        ],
      };

      for (let i = 0; i < window.reports.length; i++) {
        window.reports[i].embed_report.getActivePage().then((activePage) => {
          activePage.getVisuals().then((visuals) => {
            let slicers = visuals.filter(function (visual) {
              return visual.type === "slicer";
            });
            slicers.forEach(async (slicer) => {
              const state = await slicer.getSlicerState();

              //not using state as it will change on page load.page laod code for 1st
              if (state.targets[0].column === "Type") {
                let target_slicer = visuals.filter(function (visual) {
                  return (
                    visual.type === "slicer" && visual.name === slicer.name
                  );
                })[0];
                await target_slicer.setSlicerState({ filters: [usd_selector] });
              }

              if (state.targets[0].column === "Currency input") {
                let custom_usd_slicer = visuals.filter(function (visual) {
                  return (
                    visual.type === "slicer" && visual.name === slicer.name
                  );
                })[0];
                await custom_usd_slicer.setSlicerState({
                  filters: [currency_valuation],
                });
              }
            });
          });
        });
      }
    } else {
      window.localStorage.setItem("conversion_type", "Dynamic");
      const usd_selector = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: "Currency_USD_Type",
          column: "Type",
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: "Is",
        conditions: [
          {
            operator: "Is",
            value: conversiontype,
          },
        ],
      };
      for (let i = 0; i < window.reports.length; i++) {
        window.reports[i].embed_report.getActivePage().then((activePage) => {
          activePage.getVisuals().then((visuals) => {
            let slicers = visuals.filter(function (visual) {
              return visual.type === "slicer";
            });
            slicers.forEach(async (slicer) => {
              const state = await slicer.getSlicerState();
              //not using state as it will change on page load.page laod code for 1st
              if (state.targets[0].column === "Type") {
                let target_slicer = visuals.filter(function (visual) {
                  return (
                    visual.type === "slicer" && visual.name === slicer.name
                  );
                })[0];
                await target_slicer.setSlicerState({ filters: [usd_selector] });
              }
            });
          });
        });
      }
    }
    setMoneyModalIsOpen(false);
  };
  // vestigial in new version
  let handleTreeMenuCollapse = () => {
    settreeMenuColapse(!treemenucollapse);

    // for (let i = 0; i < window.reports.length; i++) {
    //   window.reports[i].embed_report.getActivePage().then((activePage) => {
    //     let active_ht = activePage.defaultSize.height;
    //     let active_width = activePage.defaultSize.width;
    //     let width = document.getElementsByClassName(
    //       "report-style-class-newreport" + window.reports[i].key
    //     )[0].offsetWidth;
    //     let ht = (active_ht / active_width) * width;
    //     document.getElementsByClassName(
    //       "report-style-class-newreport" + window.reports[i].key
    //     )[0].style.height = ht + "px";
    //   });
    // }
  };

  let downloadExcel = async () => {
    setExcelLoader(true);
    setShowExcel(false);
  
    let client_id = window.localStorage.getItem("clientID");
    let report_name = window.localStorage.getItem("report");
    report_name = parent_props.value;
    let report_id = window.localStorage.getItem("rep_id");
    let prop_token = window.localStorage.getItem("token");
    let url = `${process.env.REACT_APP_API_ENDPOINT}/newexcel/?client_id=${client_id}&report_id=${url_key}&is_athena=${is_athena}&industry_id=${parent_props.industry_id}&email_id=${window.localStorage.getItem('email')}`;
  
    const MAX_ATTEMPTS = Number(window.localStorage.getItem("clientID"))===53 && is_athena===true?0:3; // Maximum retry attempts
    const RETRY_DELAY = 20000; // Retry delay in milliseconds
  
    for (let attempt = 1; attempt <= MAX_ATTEMPTS; attempt++) {
      try {
        console.log(`Attempt ${attempt} to fetch the file...`);
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const res = await response.json();
        if (res["excel_link"]) {
          // File is ready; download it
          const link = document.createElement("a");
          link.href = res["excel_link"];
          link.download = report_name;
          link.click();
  
          setExcelLoader(false);
          setShowExcel(true);
          onDownloadExcelClickCT(report_name, report_id, client_id.key, true);
          return; // Exit the function as the file is downloaded
        }
      } catch (error) {
        console.error(`Error on attempt ${attempt}:`, error);
        if (attempt === MAX_ATTEMPTS) {
          console.log("Max attempts reached. Falling back to the existing flow...");
        } else {
          // Wait before retrying
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
        }
      }
    }
  
    // Fallback to the existing flow if all attempts fail
    console.log("Falling back to the existing flow...");
    fetch(url, {
      method: "GET",
      cache: "no-store",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const link = document.createElement("a");
        link.href = res["excel_link"];
        link.download = report_name;
        link.click();
  
        setExcelLoader(false);
        setShowExcel(true);
        onDownloadExcelClickCT(report_name, report_id, client_id.key, true);
      })
      .catch((error) => {
        setExcelLoader(false);
        onDownloadExcelClickCT(report_name, report_id, client_id.key, false);
        console.error(error);
      });
  };
  

  const updateProdJson = async () => {
    setButtonState('loading');

    try {
        const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/athena-prod-update/`, { "id": key });    
        if (response.status === 200) {
            setButtonState('success');
            const logData = {
                email: window.localStorage.getItem('email'),
                name: window.localStorage.getItem('user_name'),
                client_id: window.localStorage.getItem('clientID'),
                sector_name: window.localStorage.getItem('report'),
                triggered_on: new Date().toISOString(), 
            };

            await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/athena-prod-log/`, logData);

            setTimeout(() => setButtonState('default'), 3000); 
        } else {
            alert('An error occurred. Please try again.');
            setButtonState('default');
        }
    } catch (error) {
        console.error('Error updating JSON:', error);
        alert('Failed to update. Check the console for details.');
        setButtonState('default');
    }
};

  const platform_option = dropDownData;
  const cyfy_option = [{ label: "FY" }, { label: "CY" }];
  const dollar_option = [{ label: "INR" }, { label: "USD" }];

  const datefilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "date_table",

      column: "date",
    },

    filterType: models.FilterType.Advanced,

    logicalOperator: "And",

    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: window.localStorage.getItem("start_date") + "T00:00:00.000Z",
      },

      {
        operator: "LessThanOrEqual",

        value: window.localStorage.getItem("end_date") + "T00:00:00.000Z",
      },
    ],
  };
  const datefilter_max = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "db_date_table",
      column: "DB_Date",
    },
    filterType: models.FilterType.Advanced,
    logicalOperator: "And",
    conditions: [
      {
        operator: "GreaterThanOrEqual",
        value: window.localStorage.getItem("start_date") + "T00:00:00.000Z",
      },
      {
        operator: "LessThanOrEqual",
        value: window.localStorage.getItem("end_date") + "T00:00:00.000Z",
      },
    ],
  };

  const TestProdfilter = {
    $schema: "http://powerbi.com/product/schema#basic",

    target: {
      table: "Workspace_Table",

      column: "Workspace_Name",
    },

    operator: "In",

    values: [process.env.REACT_APP_ENVIRONMENT],
  };

  const basicFilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "content_data main_data",
      column: "Players",
    },

    operator: "In",
    values: [filterVal],
    // values: [window.localStorage.getItem('report')],
    filterType: models.FilterType.BasicFilter,
  };

  const ssFilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "ss_content_data player",
      column: "Players",
    },

    operator: "In",
    values: [filterVal],
    // values: [window.localStorage.getItem('report')],
    filterType: models.FilterType.BasicFilter,
  };

  const categoryfilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: { table: "ss_content_data parameter", column: "Group_Categories" },
    operator: "In",
    values: [filterVal],
  };

  const industryfilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "content_data industry",
      column: "industry_name",
    },
    operator: "In",
    values: [filterVal],
  };

  const sectorfilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: {
      table: "Grocery_Sector_Table",
      column: "Sector",
    },
    operator: "In",
    values: [filterVal],
  };

  const medicalCategoryFilter = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: { table: "ss_content_data parameter", column: "Group_Categories" },
    operator: "In",
    values: [filterVal],
  };

  let filter_arr = [datefilter, datefilter_max, TestProdfilter];
  // let filter_arr = []
  // if (parent_props.initialOpenNodes=='275'){
  //   filter_arr.push(basicFilter)
  // }
  for (let i = 0; i < filterarr.length; i++) {
    if (filterarr[i] == "1") {
      filter_arr.push(basicFilter);
    } else if (filterarr[i] === "categories") {
      filter_arr.push(categoryfilter);
    } else if (filterarr[i] === "industry") {
      filter_arr.push(industryfilter);
    } else if (filterarr[i] === "sector") {
      filter_arr.push(sectorfilter);
    } else if (filterarr[i] === "medical") {
      filter_arr.push(medicalCategoryFilter);
    } else if (filterarr[i] === "ssFilter") {
      filter_arr.push(ssFilter);
    }
  }

  if (window.localStorage.getItem("loginStatus") !== "true") {
    return <Navigate to="/" />;
  }

  function closeModal() {
    setMoneyModalIsOpen(false);
  }

  return (
    <div>
      <BodyContainer>
        {showLoader === false ? (
          <PagesWrapper width={treemenucollapse ? "80vw" : "100vw"}>
            <HeaderWrapper>
              <Header1>
                <LHSTitlediv>{selectedpage}</LHSTitlediv>
                <RHSTitlediv>
                  {window.localStorage.getItem("is_admin") === "true" ? (
                    <ChangeLogComponent
                      parent_props={parent_props}
                      url_key={url_key}
                      selectedpage={selectedpage}
                    />
                  ) : null}
                </RHSTitlediv>
              </Header1>
              <Header2>
                <LHSBreadCrumb>
                  {parent_props.headarr.length > 0 ? (
                    <>
                      {treeziparr?.map((val, i) => (
                        <BreadCrumbSpan
                          index={i}
                          onClick={(e) => {
                            if (i > 1) {
                              window.location.href = `/Report3/?val=${val[0]}&key=${val[1]}&filter=${val[3]}&filter_value=${val[2]}&industry_id=${parent_props.industry_id}`;
                            }
                          }}
                          key={i}
                        >
                          {val[0] !== "Platforms " ? `${val[0]}/` : null}
                        </BreadCrumbSpan>
                      ))}
                      {showRedseerAnalysis === true && (
                        <BreadCrumbSpan>Redseer Analysis / </BreadCrumbSpan>
                      )}
                      {showArticleDetail && (
                        <BreadCrumbSpan>Content / </BreadCrumbSpan>
                      )}
                    </>
                  ) : (
                    <span className="breadcrumbs">Consumer Internet</span>
                  )}
                </LHSBreadCrumb>
                <RHSTitlediv>
                  {excelLoader ? (
                    <TailSpin
                      height="40"
                      width="40"
                      marginRight="10"
                      color="#0099FE"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperClass=""
                      visible={true}
                    />
                  ) : null}
            {is_athena?
              <ExcelButton onClick={updateProdJson} disabled={buttonState === 'loading'}> {buttonState === 'loading' && 'Updating...'}
            {buttonState === 'success' && 'Updated to Benchmarks'}
            {buttonState === 'default' && 'Push To Prod'}</ExcelButton>
            :null}

                  {showExcel ? (
                    <ExcelDiv>
                      <ExcelButton onClick={() => downloadExcel()}>
                        Export Data
                      </ExcelButton>
                    </ExcelDiv>
                  ) : null}
                  <MyDropdown
                    options={cyfy_option}
                    onOptionSelect={onYearSelect}
                    prev_value={yearOption}
                    default_head={
                      window.localStorage.getItem("year")
                        ? window.localStorage.getItem("year")
                        : "CY"
                    }
                  />
                  <Geardiv onClick={() => handleGearClick()}>
                    <BsGear
                      style={{ fontSize: "20px", color: "#000000" }}
                    />
                  </Geardiv>
                  <GeardivMobile>
                    <BsGear
                      style={{ fontSize: "16px", color: "#000000" }}
                      onClick={() => handleGearClick()}
                    />
                  </GeardivMobile>
                  <MyDropdown
                    options={dollar_option}
                    onOptionSelect={onMoneySelect}
                    prev_value={moneyOption}
                    default_head={
                      window.localStorage.getItem("currency")
                        ? window.localStorage.getItem("currency")
                        : "INR"
                    }
                  />
                </RHSTitlediv>
              </Header2>
            </HeaderWrapper>
            {showReport ? (
              <PagesWrapper>
                {shownewsletter && (
                  <div>
                    <NewsletterSlides
                      industry_id={parent_props.industry_id}
                      rep_id={parent_props.initialOpenNodes[0]}
                      client_id={window.localStorage.getItem("clientID")}
                    />
                  </div>
                )}
                {/* {!showArticleDetail ? (
                      <QueryFooter/>
                    ) : null} */}

                {showRedseerAnalysis && (
                  <>
                    <HeaderDiv>
                      <Monthdiv>Redseer Analysis</Monthdiv>
                    </HeaderDiv>
                    <RedseerAnalysisDetail
                      pages={redseerAnalysisPages}
                      reportName={parent_props.value}
                    />
                  </>
                )}
                {showArticleDetail && (
                  <ArticleDetail selectedArticle={selectedArticle} />
                )}
                {!showArticleDetail && !showRedseerAnalysis && (
                  <div>
                    {subReportId ? (
                      subReportPagesData.filter(
                        (page) => page.element_type === "insight_header"
                      ).length ? (
                        <ReportPages
                          pages={subReportPagesData.filter(
                            (page) => page.element_type === "insight_header"
                          )}
                          outerPadding={true}
                          colorMappedPlayers={colorMappedPlayers}
                        />
                      ) : null
                    ) : reportId ? (
                      reportPagesData.filter(
                        (page) => page.element_type === "insight_header"
                      ).length ? (
                        <ReportPages
                          pages={reportPagesData.filter(
                            (page) => page.element_type === "insight_header"
                          )}
                          outerPadding={true}
                          colorMappedPlayers={colorMappedPlayers}
                        />
                      ) : null
                    ) : null}
                    {sectorPfListing ? (
                      
                      <>
                        {/* <Testing
                          industry_id={parent_props.industry_id}
                          report={parent_props.value}
                          dropDownData={dropDownData}
                          onPlayerClick={onPlayerClick}
                          listing_name={"Platform Listing"}
                        /> */}
                        
                      <TabbedHighlights reportId={Number(key)} industry_id={parent_props.industry_id}/>
                      
                      </>
                    ) : sectorPfListingFree ? (
                      <>
                        {/* <FreemiumPlayerListing
                          industry_id_prop={parent_props.industry_id}
                          url_key={url_key}
                        /> */}
              <TabbedHighlights reportId={Number(key)} industry_id={parent_props.industry_id}/>
                      </>
                    ) : null}

                    {newReportPages.length && (
                      <HeaderDiv>
                        <Monthdiv>Time Trend</Monthdiv>
                        {companyDropdown ? (
                          <div>
                            {" "}
                            <MultiSelect
                              options={companyList}
                              onSelectedChange={setSelectedCompany}
                              keyFieldName="id"
                              valueFieldName="report_name"
                              selectedOptions={companyList.filter(
                                (obj) => obj.selected
                              )}
                              mutileSelect={false}
                              sortOptions={false}
                              showCheckBox={false}
                              placeholder="Page Navigation..."
                            />
                          </div>
                        ) : null}
                      </HeaderDiv>
                    )}
                    {(() => {
                      const visiblePages = isPbPageAvailable
                        ? newReportPages.slice(0, 1)
                        : newReportPages;

                      const visibleJsonPages = subReportId
                        ? subReportPagesData.length
                          ? subReportPagesData
                          : []
                        : reportId
                        ? reportPagesData.length
                          ? reportPagesData
                          : []
                        : [];

                      return (
                        <>
                          {visiblePages.map((index, i) => {
                            if (index.page_type === "powerbi" && tokenLoaded) {
                              if (index.component_variable) {
                                let jsonObj = JSON.parse(
                                  index.component_variable.replace(/'/g, '"')
                                );
                                for (let key in jsonObj) {
                                  if (jsonObj.hasOwnProperty(key)) {
                                    let value = jsonObj[key];
                                    if (value.startsWith("$")) {
                                      value = parent_props.value;
                                    }
                                    if (key === "player") {
                                      const basicFilter1 = {
                                        $schema:
                                          "http://powerbi.com/product/schema#basic",
                                        target: {
                                          table: "content_data main_data",
                                          column: "Players",
                                        },

                                        operator: "In",
                                        values: [value],
                                        // values: [window.localStorage.getItem('report')],
                                        filterType:
                                          models.FilterType.BasicFilter,
                                      };
                                      if (index.free_page === true) {
                                        filter_arr = [];
                                      }
                                      filter_arr.push(basicFilter1);
                                    } else if (key === "categories") {
                                      const categoryfilter1 = {
                                        $schema:
                                          "http://powerbi.com/product/schema#basic",
                                        target: {
                                          table: "ss_content_data parameter",
                                          column: "Group_Categories",
                                        },
                                        operator: "In",
                                        values: [value],
                                      };
                                      filter_arr.push(categoryfilter1);
                                    } else if (key === "industry") {
                                      const industryfilter1 = {
                                        $schema:
                                          "http://powerbi.com/product/schema#basic",
                                        target: {
                                          table: "content_data industry",
                                          column: "industry_name",
                                        },
                                        operator: "In",
                                        values: [value],
                                      };
                                      filter_arr.push(industryfilter1);
                                    } else if (key === "sector") {
                                      const sectorfilter1 = {
                                        $schema:
                                          "http://powerbi.com/product/schema#basic",
                                        target: {
                                          table: "Grocery_Sector_Table",
                                          column: "Sector",
                                        },
                                        operator: "In",
                                        values: [value],
                                      };
                                      filter_arr.push(sectorfilter1);
                                    } else if (key === "medical") {
                                      const medicalCategoryFilter1 = {
                                        $schema:
                                          "http://powerbi.com/product/schema#basic",
                                        target: {
                                          table: "ss_content_data parameter",
                                          column: "Group_Categories",
                                        },
                                        operator: "In",
                                        values: [value],
                                      };
                                      filter_arr.push(medicalCategoryFilter1);
                                    }
                                  }
                                }
                              }
                              return (
                                <div
                                  key={index.id}
                                  onMouseEnter={onMouseEnterPage}
                                  onMouseLeave={() =>
                                    onMouseLeavePage(
                                      index.page_name,
                                      index.page_type,
                                      index.section_type
                                    )
                                  }
                                >
                                  <PowerBiMain
                                    index={index}
                                    i={i}
                                    filter_arr={filter_arr}
                                  />
                                </div>
                              );
                            }
                            if (index.page_type === "react_graph") {
                              let currentPages = visibleJsonPages.filter(
                                (page) =>
                                  page.page_name === index.page_name &&
                                  page.element_type !== "insight_header"
                              );
                              let firstPage=visibleJsonPages.filter(
                                (page) =>
                                  page.page_sequence === 1 &&
                                  page.element_type=== "graph"
                              )
                              let maxDate=null
                              if (firstPage.length > 0 && firstPage[0]?.data_list?.length > 0) {
                                let allDates1 = firstPage[0]?.data_list?.flatMap(item => item.data.map(d => d.date)); 
                   
                                // Make sure allDates1 is an array and has data before sorting
                                if (Array.isArray(allDates1) && allDates1.length > 0) {
                                  // Sort the dates in descending order and get the latest date
                                  maxDate = allDates1.sort((a, b) => moment(b).diff(moment(a)))[0];
                                } else {
                                  console.log("No dates available in allDates1");
                                }
                              } else {
                                console.log("No valid firstPage data found");
                              }
                              return currentPages.length && maxDate? (
                                <div
                                  key={index.id}
                                  onMouseEnter={onMouseEnterPage}
                                  onMouseLeave={() =>
                                    onMouseLeavePage(
                                      index.page_name,
                                      index.page_type,
                                      index.section_type
                                    )
                                  }
                                >
                                  <ReportPages pages={currentPages} maxDate={maxDate} colorMappedPlayers={colorMappedPlayers}/>
                                </div>
                              ) : null;
                            }
                          })}
                        </>
                      );
                    })()}

                    {isPbPageAvailable && tokenLoaded ? (
                      <Loaddiv>
                        <Textdiv onClick={handleLoadMore}>
                          {loadMore ? <div>Hide</div> : <div>Load More</div>}
                          &nbsp;
                          <IconArrow rotate={loadMore ? 270 : 90} />
                        </Textdiv>
                      </Loaddiv>
                    ) : null}

                    {/* START */}
                    <div
                      style={{
                        display: loadMore ? "flex" : "none",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {(() => {
                        const visiblePages = isPbPageAvailable
                          ? newReportPages.slice(1)
                          : [];

                        const visibleJsonPages = subReportId
                          ? subReportPagesData.length
                            ? subReportPagesData
                            : []
                          : reportId
                          ? reportPagesData.length
                            ? reportPagesData
                            : []
                          : [];
                        return (
                          <>
                            {visiblePages.map((index, i) => {
                              if (
                                index.page_type === "powerbi" &&
                                tokenLoaded
                              ) {
                                if (index.component_variable) {
                                  let jsonObj = JSON.parse(
                                    index.component_variable.replace(/'/g, '"')
                                  );
                                  for (let key in jsonObj) {
                                    if (jsonObj.hasOwnProperty(key)) {
                                      let value = jsonObj[key];
                                      if (value.startsWith("$")) {
                                        value = parent_props.value;
                                      }
                                      if (key === "player") {
                                        const basicFilter1 = {
                                          $schema:
                                            "http://powerbi.com/product/schema#basic",
                                          target: {
                                            table: "content_data main_data",
                                            column: "Players",
                                          },

                                          operator: "In",
                                          values: [value],
                                          // values: [window.localStorage.getItem('report')],
                                          filterType:
                                            models.FilterType.BasicFilter,
                                        };
                                        if (index.free_page === true) {
                                          filter_arr = [];
                                        }
                                        filter_arr.push(basicFilter1);
                                      } else if (key === "categories") {
                                        const categoryfilter1 = {
                                          $schema:
                                            "http://powerbi.com/product/schema#basic",
                                          target: {
                                            table: "ss_content_data parameter",
                                            column: "Group_Categories",
                                          },
                                          operator: "In",
                                          values: [value],
                                        };
                                        filter_arr.push(categoryfilter1);
                                      } else if (key === "industry") {
                                        const industryfilter1 = {
                                          $schema:
                                            "http://powerbi.com/product/schema#basic",
                                          target: {
                                            table: "content_data industry",
                                            column: "industry_name",
                                          },
                                          operator: "In",
                                          values: [value],
                                        };
                                        filter_arr.push(industryfilter1);
                                      } else if (key === "sector") {
                                        const sectorfilter1 = {
                                          $schema:
                                            "http://powerbi.com/product/schema#basic",
                                          target: {
                                            table: "Grocery_Sector_Table",
                                            column: "Sector",
                                          },
                                          operator: "In",
                                          values: [value],
                                        };
                                        filter_arr.push(sectorfilter1);
                                      } else if (key === "medical") {
                                        const medicalCategoryFilter1 = {
                                          $schema:
                                            "http://powerbi.com/product/schema#basic",
                                          target: {
                                            table: "ss_content_data parameter",
                                            column: "Group_Categories",
                                          },
                                          operator: "In",
                                          values: [value],
                                        };
                                        filter_arr.push(medicalCategoryFilter1);
                                      }
                                    }
                                  }
                                }

                                return (
                                  <div
                                    key={index.id}
                                    onMouseEnter={onMouseEnterPage}
                                    onMouseLeave={() =>
                                      onMouseLeavePage(
                                        index.page_name,
                                        index.page_type,
                                        index.section_type
                                      )
                                    }
                                  >
                                    <PowerBiMain
                                      index={index}
                                      i={i + 1}
                                      filter_arr={filter_arr}
                                    />
                                  </div>
                                );
                              }

                              if (index.page_type === "react_graph") {
                                let currentPages = visibleJsonPages.filter(
                                  (page) =>
                                    page.page_name === index.page_name &&
                                    page.element_type !== "insight_header"
                                );
                                return currentPages.length ? (
                                  <div
                                    key={index.id}
                                    onMouseEnter={onMouseEnterPage}
                                    onMouseLeave={() =>
                                      onMouseLeavePage(
                                        index.page_name,
                                        index.page_type,
                                        index.section_type
                                      )
                                    }
                                  >
                                    <ReportPages pages={currentPages} colorMappedPlayers={colorMappedPlayers} />
                                  </div>
                                ) : null;
                              }
                            })}
                          </>
                        );
                      })()}
                    </div>
                    {/* START END */}
                    {showsub ? (
                      <Sub onSubmitResultToCT={onSubmitResultToCT} />
                    ) : null}
                    {showIncomeStatement.current ? (
                      <div>
                        <MyStackedBarChart width={"100%"} height={"70vh"} />
                      </div>
                    ) : null}
                    <QueryFooter />

                    {showSectionReadyRec && (
                      <ComponentWrapper>
                        <ReadyReckoner
                          width={"100%"}
                          company_id={IndustryDict[url_key].player_id}
                          start_date={window.localStorage.getItem("start_date")}
                          end_date={window.localStorage.getItem("end_date")}
                        />
                      </ComponentWrapper>
                    )}
                    {showSection5 && (
                      <ComponentWrapper>
                        <BeadGraph
                          width={"100%"}
                          height={"100%"}
                          industry_id={Number(parent_props.industry_id)}
                        />
                      </ComponentWrapper>
                    )}
                    {showSection6rev && (
                      <div>
                        <WaterFallWalletShare
                          width={"100%"}
                          height={"70vh"}
                          industry_dict={`${url_key}`}
                        />
                      </div>
                    )}
                    {showRelatedDataSet && (
                      <ComponentWrapper>
                        <RelatedDataSets
                          width={"100%"}
                          height={"100%"}
                          report_id={Number(
                            window.localStorage.getItem("rep_id")
                          )}
                        />
                      </ComponentWrapper>
                    )}
                    {redseerAnalysisPages.length > 0 &&
                      redseerAnalysis &&
                      !isAnalysisLoading && (
                        <RedseerAnalysisCard
                          redseerAnalysis={redseerAnalysis}
                          onDetailClick={() => setShowRedseerAnalysis(false)}
                        />
                      )}
                    {showSection7swot && (
                      <div>
                        <h3 style={{ paddingLeft: "3.5vw" }}>
                          {selectedpage} SWOT Analysis{" "}
                        </h3>
                        <Swot />
                      </div>
                    )}
                    {/* <QueryFooter/> */}
                    {section3Pages.length > 0 && (
                      <ArticlesList
                        pages={section3Pages}
                        setSelectedArticle={(article) => {
                          const currReportId =
                            window.localStorage.getItem("rep_id");
                          const currReportName =
                            window.localStorage.getItem("report");
                          if (selectedOption) {
                            const selectedCP = platform_option.filter(
                              (opt) => opt.label === selectedOption
                            )?.[0];
                            onReadContentClickCT(
                              article?.data?.title?.rendered || "",
                              article?.data?.link || "",
                              article?.type || "",
                              window.localStorage.getItem("clientID"),
                              currReportName,
                              currReportId,
                              "Company Profile",
                              selectedCP.label,
                              selectedCP.key
                            );
                          } else {
                            onReadContentClickCT(
                              article?.data?.title?.rendered || "",
                              article?.data?.link || "",
                              article?.type || "",
                              window.localStorage.getItem("clientID"),
                              currReportName,
                              currReportId,
                              "Sector Profile"
                            );
                          }
                          setSelectedArticle(article);
                        }}
                      />
                    )}
                  </div>
                )}
              </PagesWrapper>
            ) : (
              <>
                {/* {Number(IndustryDict[key].level) === 3 ? (
                  <>
                    <FreemiumPlayerListing
                      industry_id_prop={parent_props.industry_id}
                    />
                  </>
                ) : null} */}
                {window.localStorage.getItem("report") === null ? (
                  treemenucollapse ? (
                    <Frontpage />
                  ) : (
                    <Internet />
                  )
                ) : window.localStorage.getItem("report") ===
                  "Consumer Internet" ? (
                  treemenucollapse ? (
                    <Frontpage />
                  ) : (
                    <Frontpage />
                  )
                ) : (
                  <Sub
                    rep_id="{parent_props.initialOpenNodes[0]}"
                    players={dropDownData}
                    name={parent_props.value}
                    onSubmitResultToCT={onSubmitResultToCT}
                  />
                )}
              </>
            )}
          </PagesWrapper>
        ) : (
          <div>
            <TailSpin
              height="80"
              width="80"
              color="#0099FE"
              ariaLabel="tail-spin-loading"
              radius="1"
              wrapperStyle={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
              wrapperClass=""
              visible={true}
            />
            <div
              style={{
                position: "fixed",
                top: "60%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              This may take a while
            </div>
          </div>
        )}
      </BodyContainer>
      <ModelWrapper isOpen={moneymodalIsOpen}>
        <Model>
          <CloseIconWrap>
            <CrossIcon onIconClick={closeModal} />
          </CloseIconWrap>
          <ModelBody>
            <div>
              <div>Please select USD conversion rate</div>
              <FormBox>
                <RadioWrap>
                  <CustomRadio
                    checked={conversiontype === "Dynamic"}
                    onClick={() => setConversionType("Dynamic")}
                  />
                  <CustomText>Dynamic</CustomText>
                </RadioWrap>
                <CustomWrap>
                  <RadioWrap>
                    <CustomRadio
                      checked={conversiontype === "Custom"}
                      onClick={() => setConversionType("Custom")}
                    />
                    <CustomText> Custom</CustomText>
                  </RadioWrap>
                  <CountingWrapper visible={conversiontype === "Custom"}>
                    <StyledButton
                      onClick={decCurrency}
                      isPointerCursor={currencyval > 65}
                    >
                      -
                    </StyledButton>
                    <StyledInput>{currencyval}</StyledInput>
                    <StyledButton
                      onClick={incCurrency}
                      isPointerCursor={currencyval < 100}
                    >
                      +
                    </StyledButton>
                  </CountingWrapper>
                </CustomWrap>
              </FormBox>
            </div>
            <StyledSubmitButton onClick={onCurrencyModalSubmit}>
              Submit
            </StyledSubmitButton>
          </ModelBody>
        </Model>
      </ModelWrapper>
    </div>
  );
};

export default PowerBiFrame3;

const BodyContainer = styled.div`
  display: flex;
  min-height: 90vh;
  max-height: 93vh;
  background-color: #f9fdff;
  overflow-x: hidden;
  ${media.small`
    max-height: 93vh;
  `}
`;

const SideMenuContainer = styled.div`
  overflow-y: hidden;
  /* overflow-x:hidden; */
  /* width:20vw; */
  width: ${(props) => props.width};
  background-color: #18183e;
  color: white;
  z-index: 10;
`;
const PagesWrapper = styled.div`
  width: ${(props) => props.width};
  background-color: #eef9ff;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  background-color: #ffffff;
  padding: 0.5vw 3.5vw 5px;
`;

const Header1 = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
`;

const HeaderDiv = styled.div`
  padding: 3.5vw;
  padding-top: 2vw;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  background-color: #eef9ff;
`;

const Header2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  align-items: end;
  color: grey;
  ${media.medium`
    flex-direction: row;
  `}
  ${media.small`
   flex-direction: column;
   align-items: start;
  `}
`;

const ExcelDiv = styled.div`
  grid-area: ExcelDiv;
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 45px;
`;

const ToggleButton = styled.button`
  display: ${(props) => props.display};
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #18183e;
  border-radius: 50%;
  color: white;
  border: 0px solid black;
  outline: none !important;
`;
const BreadCrumbSpan = styled.span`
  white-space: nowrap;
  &:hover {
    color: ${(props) => (props.index > 1 ? "#20a6ff" : "grey")};
    cursor: ${(props) => (props.index > 1 ? "pointer" : "arrow")};
  }
  ${media.small`
      font-size:10px;
      `}
`;

const ExcelButton = styled.button`
  background-color: white;
  border: 1px solid #b9bec1;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  outline: none !important;
  ${media.small`
    border-radius: 3px;
    border: 0.5px solid #b9bec1;
    font-size: 12px;
    padding: 5px;
  `}
`;

const LHSTitlediv = styled.div`
  font-weight: 500;
  font-size: 30px;
  ${media.small`
      font-size: 20px;

    
    `}
`;

const LHSBreadCrumb = styled.div`
  display: flex;
  ${media.small`
    margin-bottom: 10px;
        font-size:11px;

  `}
`;

const RHSTitlediv = styled.div`
  display: flex;
  gap: 10px;
  ${media.small`
    font-size:9px;
    
    `}
`;

const Geardiv = styled.div`
  border-radius: 5px;
  border: 1px solid #b9bec1;
  background-color: white;
  padding: 10px;
  cursor: pointer;
  ${media.small`
    display: none;
  `}
`;

const GeardivMobile = styled.div`
  background-color: white;
  display: none;
  cursor: pointer;
  ${media.small`
    display: flex;
    border-radius: 3px;
    border: 0.5px solid #b9bec1;
    font-size: 12px;
    padding: 5px;
  `}
`;

const Loaddiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 2px;
  background-color: #d9d9d9;
  margin: 1.75vw 3.5vw;
`;

const Textdiv = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #eef9ff;
  padding: 0 10px;
  cursor: pointer;
  color: #5cbeff;
`;

const IconArrow = styled(ArrowIcon)`
  height: 18px;
  width: 18px;
`;

const Monthdiv = styled.div`
  font-weight: 500;
  font-size: 25px;
  ${media.small`
      font-size: 18px;
    `}
`;
const ComponentWrapper = styled.div`
  padding: calc(3.5vw - 10px);
  padding-top: 10px;
`;

const ModelWrapper = styled.div`
  display: ${(porps) => (porps.isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #00000099;
  z-index: 20;
`;

const Model = styled.div`
  flex-direction: column;
  width: 450;
  max-width: 25%;
  background-color: #ffffff;
  z-index: 5;
  box-shadow: 2px 2px 4px 0px #00000040;
  border: 0.75px solid #ededed;
  border-radius: 5px;
  color: #262e40;
  cursor: default;
  ${media.small`
    height: 32vh;
    min-width: calc(100% - 10px);
    box-shadow: 1px 1px 2px 0px #00000000;
    border: 0.25px solid #ededed;
    border-radius: 2px;
  `}
`;

const ModelBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 34px);
  justify-content: space-between;
  padding: 0 30px 30px 30px;
`;

const CloseIconWrap = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  height: 34px;
  padding: 10px 10px 20px 0px;
`;

const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const CustomWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 70px;
`;

const RadioWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 5px 0px;
`;

const CustomRadio = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: ${(props) => (props.checked ? "#0099FF" : "#FFFFFF")};
  border-radius: 50%;
  border: 2px solid #ddd;
  position: relative;
  margin-right: 8px;
  cursor: pointer;
`;

const CustomText = styled.span`
  font-size: 16px;
  padding-left: 8px;
`;

const CountingWrapper = styled.div`
  display: ${(props) => (props.visible ? "flex" : "none")};
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #b9bec1;
  border-radius: 5px;
  background-color: #ffffff;
  color: black;
  margin: 0px 5px;
`;

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  justify-content: space-around;
  min-width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #ffffff;
  color: black;
  cursor: ${(props) => (props.isPointerCursor ? "pointer" : "not-allowed")};
`;

const StyledInput = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: calc(100% - 60px);
  height: 30px;
  border-left: 1px solid #b9bec1;
  border-right: 1px solid #b9bec1;
  background-color: #ffffff;
  color: black;
`;

const StyledSubmitButton = styled.button`
  color: white;
  background-color: #0099ff;
  margin-top: 10px;
  border-radius: 6px;
  border: 0px;
  height: 30px;
  cursor: pointer;
`;
