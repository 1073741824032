import TreeDropDn from "../../TreeDropDn/TreeDropDn";
import styled from "styled-components";
import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { media } from "../../../utils/media";

const SectorSignIn = () => {
  const [isSectorOpen, setSectorOpen] = useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [showPopup, setShowPopup] = useState(false);
  const myElementRef = useRef(null);
  const [maxWidth, setmaxWidth] = useState(0);
  useEffect(() => {
    if (myElementRef.current) {
      const elementWidth = myElementRef.current.clientWidth;
      setmaxWidth(-elementWidth / 2 + 140);
    }
  }, [isSectorOpen]);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, []);

  return (
    <HomeDiv>
      <NavLink
        isactive={activeLink === "/Report3/" || isSectorOpen}
        onMouseEnter={() => {
          setSectorOpen(true);
        }}
        onMouseLeave={() => {
          setSectorOpen(false);
        }}
      >
        Sectors
      </NavLink>

      <PopRef>
        <TreeDropDnDiv
          ref={myElementRef}
          left={maxWidth}
          onMouseEnter={() => {
            setSectorOpen(true);
          }}
          onMouseLeave={() => {
            setSectorOpen(false);
          }}
        >
          <TreeDropDn
            isOpen={isSectorOpen}
            setIsOpen={(val) => setSectorOpen(val)}
          />
        </TreeDropDnDiv>
        {/* <ShadowBackground visible={isSectorOpen} />  old header*/}
      </PopRef>
    </HomeDiv>
  );
};
export default SectorSignIn;

const HomeDiv = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const PopRef = styled.div`
  position: relative;
  width: 100%;
  height: 0px;
`;

const TreeDropDnDiv = styled.div`
  font-size: 15px;
  white-space: nowrap;
  z-index: 7;
  top: calc(7vh - 2px);
    position: fixed;
    //New header- margin-left only
    margin-left: ${({ left }) => `${-left}px`}; 
    // Old header uncomment thses two and comment margin-left
  // width: 100%;
  // left: 0; 
`;

const ShadowBackground = styled.div`
  display: ${(props) => (props.visible ? "flex" : "none")};
  z-index: 6;
  top: 7vh;
  left: 0;
  width: 100%;
  height: 93vh;
  position: fixed;
  background-color: #00000099;
`;

const SectorDataAlign = styled.div`
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;
`;

const NavLink = styled.div`
  display: flex;
  height: 100%;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 25px;
  align-items: center;
  color: ${(props) => (props.isactive ? "#0099FF" : "#000000")};
  &:hover {
    color: #0099ff;
    cursor: pointer;
  }
  ${media.small`
    font-size: 12px;
    margin: 0px 2.5px;
  `}
`;
