import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import Header from "../../../components/Header";
import {
  WEBFORM_TOKEN,
  HOME_URL,
  TAG_ENDPOINT,
  SIGNIN_URL,
  THESIS_FILTER_ENDPOINT,
  PARAMETER_GROUP_ENDPOINT,
} from "../../../constants/constants";
import MultiSelect from "../../../components/MultiSelect";
import SearchIcon from "../../../components/svg/SearchIcon";
import { useNavigate } from "react-router-dom";
import StyledTable from "../../../components/StyledTable";
import Loader from "../../../components/Loader";
import axios from "axios";
import moment from "moment";
import ExternalLinkIcon from "../../../components/svg/ExternalLinkIcon";
import { Switch } from "antd";
import LineGraph from "../../../components/LineGraph";
import RatingCircle from "../../../components/RatingCircle";
import MasterTable from "../../../components/MasterTable";
import InternetCheck from "../../../components/svg/InternetCheck";
import DefaultPin from "../../../components/svg/DefaultPin";
import BookmarkBin from "../../../components/svg/BookmarkBin";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import USDandFyrow from "../../../components/USDandFYrow";
import { models } from "powerbi-client";
import ScoreBargraph from "../../../components/ScoreBarGraph";
import PlayerLink from "../../../components/svg/PlayerLink";
import { media } from "../../../utils/media";
import ScoreBargraph_one from "../../../components/ScoreBarGraph/ScoreBarGraphParts/index_one";
import ScoreBargraph_two from "../../../components/ScoreBarGraph/ScoreBarGraphParts/index_two";
import { onCompanyProfileClickCT,onSectorReportOpenCT} from "../../../utils/clevertap";
 

const PlayerListing = ({
  frequency = "monthly",
  end_date = moment(moment().startOf("month")).format("YYYY-MM-DD"),
  table_type = "iil_player_listing",
  search_player = null,
}) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  // API whole data
  const [clientId, setClientId] = useState(
    Number(window.localStorage.getItem("clientID")) || null
  );
  const [allCompanies, setAllCompanies] = useState([]);
  const [visibleCompanies, setVisibleCompanies] = useState([]);

  const [isDataLoaded, setDataLoaded] = useState(false);
  const IndustryDict = JSON.parse(window.localStorage.getItem("IndustryDict"));
  const [conversiontype, setConversionType] = useState("Custom");
  const [currencyval, setCurrencyVal] = useState(79);
  const [bookmarkedSectors, setBookmarkedSectors] = useState([]);
  const [selectedHeader, setSelectedHeader] = useState("Subscribed");
  const [tableDate, setTableDate] = useState(null);
  let subDictionary = JSON.parse(window.localStorage.getItem("subDictionary"));

  let rowsData = [];

  const getLabel = (dataDate) => {
    const dataDateObj = moment(dataDate, "YYYY-MM-DD");
    if (frequency === "monthly") {
      return dataDateObj.format("MMM'YY");
    } else if (frequency === "quarterly") {
      const quarter = Math.floor((dataDateObj.month() + 3) / 3);
      switch (quarter) {
        case 1:
          return `JFM'${dataDateObj.format("YY")}`;
        case 2:
          return `AMJ'${dataDateObj.format("YY")}`;
        case 3:
          return `JAS'${dataDateObj.format("YY")}`;
        case 4:
          return `OND'${dataDateObj.format("YY")}`;
        default:
          return "Invalid Date";
      }
    }
  };

  const getNameHeader = (count) => {
    if (count <= 1) {
      return (
        <CountTextWrap>
          <HeaderNameDiv>Name</HeaderNameDiv>
          <CountText>({count} Result)</CountText>
        </CountTextWrap>
      );
    }
    return (
      <CountTextWrap>
        <HeaderNameDiv>Name</HeaderNameDiv>
        <CountText>({count} Results)</CountText>
      </CountTextWrap>
    );
  };

  const [columnsData, setColumnsData] = useState([
    {
      key: "player",
      value: getNameHeader(0),
      sorting: false,
      range_filter: false,
      value_filter: false,
      min_width: null,
    },
  ]);
  const [isColumnsLoaded, setColumnsLoaded] = useState(false);
  const [parameterRange, setParameterRange] = useState({});
  const [parameterValueFilter, setParameterValueFilter] = useState({});
  const [styledRowsData, setStyledRowsData] = useState([]);

  const [compSearchString, setCompSearchString] = useState(
    useQuery().get("company") || ""
  );

  const [indReportMapping, setIndReportMapping] = useState([]);
  const [indWiseCompReport, setIndWiseCompReport] = useState([]);
  const [isReportMappingLoaded, setReportMappingLoaded] = useState(false);

  const [industryIdList, setIndustryIdList] = useState([]);
  const [dataMonth, setDataMonth] = useState(null);

  const [tags, setTags] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [valuationList, setValuationList] = useState([
    {
      id: 1,
      name: "0 - 100 Mn (USD)",
      low: 0,
      high: 100,
      selected: false,
    },
    {
      id: 2,
      name: "100 Mn - 500 Mn (USD)",
      low: 100,
      high: 500,
      selected: false,
    },
    {
      id: 3,
      name: "500 Mn - 2 Bn (USD)",
      low: 500,
      high: 2000,
      selected: false,
    },
    {
      id: 4,
      name: "2 Bn - 5 Bn (USD)",
      low: 2000,
      high: 5000,
      selected: false,
    },
    {
      id: 5,
      name: "5 Bn - 10 Bn (USD)",
      low: 5000,
      high: 10000,
      selected: false,
    },
    {
      id: 6,
      name: "10 Bn + (USD)",
      low: 10000,
      high: 100000000,
      selected: false,
    },
  ]);
  const [sortedParameter, setSortedParameter] = useState(null);
  const [sortedDirection, setSortedDirection] = useState("");

  const navigate = useNavigate();

  const treeziparr = [
    { key: 1, name: "Home" },
    { key: 2, name: "internet-landscape" },
  ];

  useEffect(() => {
    if (!window.localStorage.getItem("loginStatus")) navigate(SIGNIN_URL);
  }, []);

  const handleHeaderClick = (header) => {
    setSelectedHeader(header);
    const sorted = [...styledRowsData]
      .sort((a, b) => {
        if (header === "Top Rated") {
          const aTopRated = a[8239]?.top_rated ?? -Infinity;
          const bTopRated = b[8239]?.top_rated ?? -Infinity;
          return bTopRated - aTopRated;
        }
        if (header === "Subscribed") {
          const aTopSubscribed = a[8244]?.top_subscribed ?? -Infinity;
          const bTopSubscribed = b[8244]?.top_subscribed ?? -Infinity;
          return bTopSubscribed - aTopSubscribed;
        }
        if (header === "Top Growing") {
          const aYoyGrowth = a[8244]?.top_growing?.yoy_growth || null;
          const bYoyGrowth = b[8244]?.top_growing?.yoy_growth || null;

          if (bYoyGrowth == null && aYoyGrowth == null) {
            return 0;
          } else if (bYoyGrowth == null) {
            return -1;
          } else if (aYoyGrowth == null) {
            return 1;
          }

          return bYoyGrowth - aYoyGrowth;
        }
        return 0;
      })
      .sort((a, b) => {
        if (a["player"].bookMarkValue === 0) return 1;
        else if (b["player"].bookMarkValue === 0) return -1;
        else if (a["player"].bookMarkValue > b["player"].bookMarkValue)
          return 1;
        else if (a["player"].bookMarkValue < b["player"].bookMarkValue)
          return -1;
        return a["player"].bookMarkValue - a["player"].bookMarkValue;
      });
    setStyledRowsData(sorted);
    // setSortedData(sorted.slice(0, 10));
  };

  useEffect(() => {
    const sorted = [...styledRowsData]
      .sort((a, b) => {
        if (selectedHeader === "Top Rated") {
          const aTopRated = a[8239]?.top_rated ?? -Infinity;
          const bTopRated = b[8239]?.top_rated ?? -Infinity;
          return bTopRated - aTopRated;
        }
        if (selectedHeader === "Subscribed") {
          const aTopSubscribed = a[8244]?.top_subscribed ?? -Infinity;
          const bTopSubscribed = b[8244]?.top_subscribed ?? -Infinity;
          return bTopSubscribed - aTopSubscribed;
        }
        if (selectedHeader === "Top Growing") {
          const aYoyGrowth = a[8244]?.top_growing?.yoy_growth || null;
          const bYoyGrowth = b[8244]?.top_growing?.yoy_growth || null;

          if (bYoyGrowth == null && aYoyGrowth == null) {
            return 0;
          } else if (bYoyGrowth == null) {
            return -1;
          } else if (aYoyGrowth == null) {
            return 1;
          }

          return bYoyGrowth - aYoyGrowth;
        }
        return 0;
      })
      .sort((a, b) => {
        if (a["player"].bookMarkValue === 0) return 1;
        else if (b["player"].bookMarkValue === 0) return -1;
        else if (a["player"].bookMarkValue > b["player"].bookMarkValue)
          return 1;
        else if (a["player"].bookMarkValue < b["player"].bookMarkValue)
          return -1;
        return a["player"].bookMarkValue - a["player"].bookMarkValue;
      });
  }, [styledRowsData]);

  let handleClickTree = (index) => {
    if (treeziparr[index].key === 1) {
      navigate(HOME_URL);
    }
  };

  const setSelectedIndustry = (selectedIndustry) => {
    setIndustryList(
      industryList.map((ind) => {
        return {
          ...ind,
          selected: selectedIndustry.some(
            (selectedInd) => selectedInd.industry_id === ind.industry_id
          ),
        };
      })
    );
  };

  const setSelectedValuation = (selectedValuationList) => {
    setValuationList(
      valuationList.map((valuation) => {
        return {
          ...valuation,
          selected: selectedValuationList.some(
            (selectedValuation) => selectedValuation.id === valuation.id
          ),
        };
      })
    );
  };

  useEffect(() => {
    axios
      .get(`${TAG_ENDPOINT}/?type=thesis-filter`)
      .then((response) => response.data)
      .then((tagData) => {
        setTags(
          tagData.map((obj) => ({
            ...obj,
            selected: false,
          }))
        );
      })
      .catch((error) => {
        console.error("Error in fetching tags in landscape player listing-", error);
      });
  }, []);

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        PARAMETER_GROUP_ENDPOINT,
        {
          table_type: table_type,
          industry_id: 40,
          profile: "overall",
          data_category: "overall",
          data_frequency: frequency,
          end_date: end_date,
        },
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.status === 200) {
          const responseData = response.data;
          setTableDate(responseData.data_date);

          // responseData.players_list = responseData.players_list.filter(
          //   (play) => play.company_profile_id in subDictionary
          // );
          if (!isColumnsLoaded) {
            let updatedParameters = [
              {
                key: "player",
                value: getNameHeader(responseData.players_list.length),
                sorting: false,
                range_filter: false,
                value_filter: false,
                min_width: null,
              },
            ];
            response.data.parameter_list
              .sort((a, b) => a.sequence - b.sequence)
              .map((parObj) => {
                updatedParameters.push({
                  key: parObj.parameter,
                  value: (
                    <HeaderWrapper>
                      {
                        parObj.name==='Redseer Score'?
                      <HeaderText>
                        {parObj.name} 
                        <div style={{paddingLeft:"5%", color:"red"}}> (&beta;eta)</div>
                        </HeaderText>
:                      <HeaderText>{parObj.name}</HeaderText>

                      }
                      {parObj.unit ? (
                        <HeaderText>({parObj.unit})</HeaderText>
                      ) : null}
                    </HeaderWrapper>
                  ),
                  sorting: parObj.sorting,
                  range_filter: parObj.range_filter,
                  range_filter_data: parObj.range_filter_data,
                  value_filter: parObj.value_filter,
                  min_width: parObj.col_width,
                });
              });

            // updatedParameters.push({
            //   key:"serial",
            //   value: "S.No.",
            //   sorting: true,
            //   range_filter: false,
            //   range_filter_data: null,
            //   value_filter: null,
            //   min_width: null,
            // }
            // )
            setColumnsData(updatedParameters);
            setColumnsLoaded(true);
          }
          const industry_id_set = new Set();
          let industryDataList = [];
          let companiesData = responseData.players_list.map((comp) => {
            if (
              !industry_id_set.has(comp.industry_id) &&
              comp.player_id
              // && comp.company_profile_id in subDictionary
            ) {
              industry_id_set.add(comp.industry_id);
              industryDataList.push({
                company_id: comp.company_id,

                industry_id: comp.industry_id,
                player_name: comp.player_name,
                industry_name: comp.industry_name,
                selected: false,
              });
            }
            return {
              ...comp,
              param_data: responseData.players_data[comp.player_id],
            };
          });
          companiesData = companiesData
            .sort((a, b) => {
              if (a.industry_id === 10 && b.industry_id !== 10) {
                return -1;
              } else if (a.industry_id !== 10 && b.industry_id === 10) {
                return 1;
              } else {
                return 0;
              }
            })
            .sort((a, b) => {
              if (a.industry_id === 18 && b.industry_id !== 18) {
                return -1;
              } else if (a.industry_id !== 18 && b.industry_id === 18) {
                return 1;
              } else {
                return 0;
              }
            });
          setAllCompanies(companiesData);
          setVisibleCompanies(companiesData);
          const industry_id_list = Array.from(industry_id_set);
          setIndustryIdList(industry_id_list);
          setIndustryList(industryDataList);

          setDataLoaded(true);
        } else {
          console.error(
            "Error in fetching parameter group in landscape player listing ",
            response.error.message
          );
          setDataLoaded(true);
        }
      })
      .catch((error) => {
        console.error(
          "Error in fetching parameter group for landscape player listing -",
          error
        );
        setDataLoaded(true);
      });
    let newIndustryReportMapping = [];
    let newReportTree = JSON.parse(window.localStorage.getItem("newReporTree"));
    if (newReportTree) {
      newIndustryReportMapping = getNodesAtLevel(newReportTree, 3);
      setIndReportMapping(newIndustryReportMapping);

      // }
      // API CALL
      let newIndustryWiseCompanyReport = {};
      newIndustryWiseCompanyReport =
        groupLevel5NodesByParentLevel3(newReportTree);

      setIndWiseCompReport(newIndustryWiseCompanyReport);
      setReportMappingLoaded(true);
    }
  }, []);

  useEffect(() => {
    const client_id = window.localStorage.getItem("clientID");
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/client-bookmark/?client_id=${client_id}&type=${table_type}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 200) {
          setBookmarkedSectors(response.data);
        }
      })
      .catch((error) =>
        console.error("Error fetching subscriber data for landscape player listing: ", error)
      );
  }, []);

  const onBookmarkSector = (player_id) => {
    const headers = {
      "Content-Type": "application/json",
    };
    const client_id = Number(window.localStorage.getItem("clientID"));

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/client-bookmark/`,
        {
          client_id: client_id,
          type: table_type,
          value: player_id,
        },
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.status === 200) {
          let bookmarkList = [];
          for (let i = 0; i < bookmarkedSectors.length; i++) {
            bookmarkList.push(bookmarkedSectors[i]);
          }
          bookmarkList.push(response.data);
          setBookmarkedSectors(bookmarkList);
        }
      })
      .catch((error) =>
        console.error("Error fetching subscriber data for landscape player listing: ", error)
      );
  };

  const onUnpinSector = (bookmark_id) => {
    const headers = {
      "Content-Type": "application/json",
    };

    axios
      .delete(
        `${process.env.REACT_APP_API_ENDPOINT}/client-bookmark/${bookmark_id}/`,
        {
          headers: headers,
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response.status === 200) {
          let bookmarkList = bookmarkedSectors;
          bookmarkList = bookmarkList.filter(
            (bookmark) => bookmark.id !== bookmark_id
          );
          setBookmarkedSectors(bookmarkList);
        }
      })
      .catch((error) => console.error("Error deleting bookmark for landscape player listing: ", error));
  };

  useEffect(() => {
    if (isColumnsLoaded) {
      const ColumnsValueFilter = {};
      const valueFilterColumns = columnsData.filter((col) => col.value_filter);
      allCompanies.forEach((comp) => {
        valueFilterColumns.forEach((col) => {
          if (!ColumnsValueFilter[col.key]) {
            ColumnsValueFilter[col.key] = new Set();
          }
          if (comp.param_data[col.key].sortingValue) {
            ColumnsValueFilter[col.key].add(
              comp.param_data[col.key].sortingValue
            );
          }
        });
      });
      valueFilterColumns.forEach((col) => {
        ColumnsValueFilter[col.key] = Array.from(
          ColumnsValueFilter[col.key] || []
        ).map((name, idx) => ({
          id: idx,
          name: name,
          selected: false,
        }));
      });
      setParameterValueFilter(ColumnsValueFilter);
    }
  }, [allCompanies, isColumnsLoaded]);

  const onTagClick = (tagId) => {
    const updatedTags = tags.map((obj) => {
      return {
        ...obj,
        selected: obj.id === tagId ? !obj.selected : obj.selected,
      };
    });
    const commaSeperatedTagList = updatedTags
      .filter((obj) => obj.selected)
      .map((obj) => obj.id)
      .join(",");
    window.localStorage.setItem("thesis_selected_tags", commaSeperatedTagList);

    setTags(
      tags.map((obj) => {
        return {
          ...obj,
          selected: obj.id === tagId ? !obj.selected : obj.selected,
        };
      })
    );
  };

  const onRangeFilterApply = (parameter_id, selectedRange) => {
    setParameterRange({ ...parameterRange, [parameter_id]: selectedRange });
  };

  const onValueFilterApply = (parameter_id, valueFilter) => {
    setParameterValueFilter({
      ...parameterValueFilter,
      [parameter_id]: valueFilter,
    });
  };
  useEffect(() => {
    IndustryDict &&
      visibleCompanies.forEach((company) => {
        const redirectRep = IndustryDict[company.company_profile_id] || null;

        let subscribedReport = null;
        if (company.industry_id) {
          subscribedReport = IndustryDict[company.player_id] || null;
        }

        const bookMark = bookmarkedSectors.filter(
          (obj) => obj.value === company.player_id
        );
        const spReport = IndustryDict[company.sector_profile_id] || null;
        const bookMarkValue = bookMark.length > 0 ? bookMark[0].id : 0;
        const isSubscribed = company.company_profile_id in subDictionary;
        let rowData = {
          player: {
            value: (
              <>
                <PlayerWrap>
                  <div>
                    {company.icon_url ? (
                      <StyledImage src={company.icon_url} alt="Company Icon" />
                    ) : (
                      <StyledImage
                        src="https://aredstorage.blob.core.windows.net/images/companylogo/100%20x%20100/default_icon.png"
                        alt="Default Company Icon"
                      />
                    )}
                  </div>
                  <CompanyNameWrap>
                    <ColumnWarp>
                      <SectorProfile>
                        <CpProfileLink
                          islink={Boolean(redirectRep) ? "true" : "false"}
                          onClick={() => {
                            if (Boolean(redirectRep)) {
                              const url = `/Report3/?val=${redirectRep.report_name}&key=${redirectRep.id}&filter=${redirectRep.filter}&filter_value=${redirectRep.filter_value}`;
                              window.open(url, "_blank");
                            }
                            onCompanyProfileClickCT(redirectRep.report_name,redirectRep.id,spReport.report_name,spReport.id)

                          }}

                        >
                          {company.player_name}
                          {isSubscribed ? <InternetCheck /> : null}
                        </CpProfileLink>
                        <div
                          onClick={() => {
                            if (company.website_url) {
                              const url = company.website_url;
                              window.open(url, "_blank");
                            }
                          }}
                          style={{
                            cursor: company.website_url ? "pointer" : "default",
                          }}
                        >
                          <PlayerLink />
                        </div>
                      </SectorProfile>
                      <SpProfileLink
                        islink={Boolean(spReport) ? "true" : "false"}
                        onClick={() => {
                          if (Boolean(spReport)) {
                            const url = `/Report3/?val=${spReport.report_name}&key=${spReport.id}&filter=${spReport.filter}&filter_value=${spReport.filter_value}`;
                            window.open(url, "_blank");
                          }
                          onSectorReportOpenCT(spReport.report_name,spReport.id,isSubscribed)

                        }}
                      >
                        <div>{company.industry_name}</div>
                      </SpProfileLink>
                      <TagHover>
                        {company.tags.map((tag) => {
                          if (tag.file) {
                            return (
                              <ImageContainer key={tag.name}>
                                <img
                                  src={
                                    process.env.REACT_APP_WEBFORM_ENDPOINT +
                                    tag.file
                                  }
                                  alt={tag.name}
                                />
                                <Tooltip className="tooltip">
                                  {tag.name}
                                </Tooltip>
                              </ImageContainer>
                            );
                          }
                          return null;
                        })}
                      </TagHover>
                    </ColumnWarp>
                    <div>
                      <BookmarkBin
                        selected={bookMark.length > 0 ? true : false}
                        onIconClick={() =>
                          bookMark.length > 0
                            ? onUnpinSector(bookMark[0].id)
                            : onBookmarkSector(company.player_id)
                        }
                      />
                    </div>
                  </CompanyNameWrap>
                </PlayerWrap>
              </>
            ),
            sortingValue: company.player_name,
            isSubscribed: isSubscribed,
            bookMarkValue: bookMarkValue,
            toolTip: null,
          },
        };

        columnsData.forEach((col) => {
          if (col.key !== "player" && company.param_data) {
            const param = company.param_data[col.key];
            rowData[col.key] = {};

            if ("value" in param) {
              rowData[col.key].value = param.value;
              rowData[col.key].top_subscribed = param.value;
            }
            if ("value2" in param) {
              rowData[col.key].value2 = param.value2;
            }
            if ("value3" in param) {
              rowData[col.key].value3 = param.value3;
              if (Array.isArray(rowData[col.key].value3))
                rowData[col.key].top_growing =
                  rowData[col.key].value3[rowData[col.key].value3.length - 1];
            }
            if ("value4" in param) {
              rowData[col.key].value4 = param.value4;
            }
            // if ("sortingValue" in param) {
            //   rowData[col.key].sortingValue = param.value;
            // }
            if ("value5" in param) {
              rowData[col.key].value5 = param.value5;
              rowData[col.key].top_rated = param.value5;
              rowData[col.key].sortingValue = param.value5;
            }
          }
        });
        rowsData.push(rowData);
      });

    rowsData = rowsData
      .sort((a, b) => {
        const aVal = a[8239]?.sortingValue || 0;
        const bVal = b[8239]?.sortingValue || 0;
        if (typeof aVal === "number" && typeof bVal === "number") {
          return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
        } else if (typeof aVal === "string" && typeof bVal === "string") {
          return sortedDirection === "asc"
            ? aVal.localeCompare(bVal)
            : bVal.localeCompare(aVal);
        } else {
          return 0;
        }
      })
      .sort((a, b) => {
        if (a["player"].isSubscribed && !b["player"].isSubscribed) {
          return -1;
        }
        if (!a["player"].isSubscribed && b["player"].isSubscribed) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => {
        if (a["player"].bookMarkValue === 0) return 1;
        else if (b["player"].bookMarkValue === 0) return -1;
        else if (a["player"].bookMarkValue > b["player"].bookMarkValue)
          return 1;
        else if (a["player"].bookMarkValue < b["player"].bookMarkValue)
          return -1;
        return a["player"].bookMarkValue - a["player"].bookMarkValue;
      });

    rowsData = rowsData.map((row) => {
      if (row[8239]) {
        return {
          ...row,
          8239: {
            ...row[8239],
            value: (
              <>
                <IndustrySizeWrap>
                  <div
                    style={{
                      flex: "0 1 55%",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <RedseerScoreWrap>
                      <RatingCircle
                        props={Math.round(row[8239].value5 * 10) / 10}
                        height={60}
                        width={60}
                      />
                      <ScoreBargraph_one props={row[8239]} width={160} />
                    </RedseerScoreWrap>
                  </div>
                  <div
                    style={{
                      top: 0,
                      bottom: 0,
                      width: "0.5px",
                      backgroundColor: "#D1D1D1",
                    }}
                  ></div>
                  <div
                    style={{
                      flex: "0 1 35%",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <RedseerScoreWrap>
                      <ScoreBargraph_two props={row[8239]} width={160} />
                    </RedseerScoreWrap>
                  </div>
                </IndustrySizeWrap>
              </>
            ),
          },
        };
      }
      return row;
    });

    rowsData = rowsData.map((row) => {
      if (row[8244] && (row[8244].value || row[8244].value3)) {
        let transformedData = [];

        transformedData = row[8244].value3
          ? row[8244].value3.map((item) => ({
              yoy_growth: item.yoy_growth,
            }))
          : [];
        return {
          ...row,
          8244: {
            ...row[8244],
            value: (
              <>
                <IndustrySizeWrap>
                  <div
                    style={{
                      flex: "0 1 15%",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    {row[8244].value && (
                      <Container>
                        <Element1>
                          {Math.round(row[8244].value * 10) / 10}
                        </Element1>
                        {<Element2> {row[8244].value2}</Element2>}
                        <Element3>(INR Cr)</Element3>
                      </Container>
                    )}
                  </div>
                  <div
                    style={{
                      top: 0,
                      bottom: 0,
                      width: "0.5px",
                      backgroundColor: "#D1D1D1",
                    }}
                  ></div>
                  <div style={{ flex: "0 1 80%" }}>
                    {transformedData.length > 1 && (
                      <>
                        <div>
                          <LineGraph data={transformedData} height={50} />
                          <YoyFontWrap>
                            <span
                              style={{
                                color:
                                  transformedData[transformedData.length - 1]
                                    .yoy_growth >= 0
                                    ? "#21AF4A"
                                    : "#DA1E28",
                              }}
                            >
                              {
                                transformedData[transformedData.length - 1]
                                  .yoy_growth
                              }
                              %{" "}
                            </span>
                            yoy growth with
                          </YoyFontWrap>
                          <YoyFontWrap>
                            {transformedData[transformedData.length - 1]
                              .yoy_growth *
                              transformedData[transformedData.length - 2]
                                .yoy_growth >
                              0 &&
                            Math.abs(
                              Math.abs(
                                transformedData[transformedData.length - 1]
                                  .yoy_growth
                              ) -
                                Math.abs(
                                  transformedData[transformedData.length - 2]
                                    .yoy_growth
                                )
                            ) /
                              Math.abs(
                                transformedData[transformedData.length - 2]
                              ) <=
                              0.1
                              ? "stable momentum"
                              : transformedData[transformedData.length - 1]
                                  .yoy_growth -
                                  transformedData[transformedData.length - 2]
                                    .yoy_growth >
                                0
                              ? transformedData[transformedData.length - 1]
                                  .yoy_growth > 0 &&
                                transformedData[transformedData.length - 2]
                                  .yoy_growth > 0
                                ? "accelerating momentum"
                                : "volatile growth momentum"
                              : transformedData[transformedData.length - 1]
                                  .yoy_growth > 0 &&
                                transformedData[transformedData.length - 2]
                                  .yoy_growth > 0
                              ? "decelerating momentum"
                              : "volatile degrowth momentum"}
                          </YoyFontWrap>
                        </div>
                      </>
                    )}
                  </div>
                </IndustrySizeWrap>
              </>
            ),
          },
        };
      }
      return row;
    });

    setColumnsData(
      columnsData.map((col) => {
        return {
          ...col,
          value:
            col.key === "player" ? getNameHeader(rowsData.length) : col.value,
        };
      })
    );

    if (sortedParameter) {
      setStyledRowsData(
        [...rowsData]
          .sort((a, b) => {
            const aVal = a[sortedParameter]?.sortingValue;
            const bVal = b[sortedParameter]?.sortingValue;
            if (aVal == null && bVal == null) {
              return 0;
            } else if (aVal == null) {
              return 1;
            } else if (bVal == null) {
              return -1;
            }

            if (typeof aVal === "number" && typeof bVal === "number") {
              return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
            } else if (typeof aVal === "string" && typeof bVal === "string") {
              return sortedDirection === "asc"
                ? aVal.localeCompare(bVal)
                : bVal.localeCompare(aVal);
            } else {
              return 0;
            }
          })
          .sort((a, b) => {
            if (a["player"].isSubscribed && !b["player"].isSubscribed) {
              return -1;
            }
            if (!a["player"].isSubscribed && b["player"].isSubscribed) {
              return 1;
            }
            return 0;
          })
          .sort((a, b) => {
            if (a["player"].bookMarkValue === 0) return 1;
            else if (b["player"].bookMarkValue === 0) return -1;
            else if (a["player"].bookMarkValue > b["player"].bookMarkValue)
              return 1;
            else if (a["player"].bookMarkValue < b["player"].bookMarkValue)
              return -1;
            return a["player"].bookMarkValue - a["player"].bookMarkValue;
          })
      );
    } else {
      setStyledRowsData(
        [...rowsData]
          .sort((a, b) => {
            if (selectedHeader === "Top Rated") {
              const aTopRated = a[8239]?.top_rated ?? -Infinity;
              const bTopRated = b[8239]?.top_rated ?? -Infinity;
              return bTopRated - aTopRated;
            }
            if (selectedHeader === "Subscribed") {
              const aTopSubscribed = a[8244]?.top_subscribed ?? -Infinity;
              const bTopSubscribed = b[8244]?.top_subscribed ?? -Infinity;
              return bTopSubscribed - aTopSubscribed;
            }
            if (selectedHeader === "Top Growing") {
              const aYoyGrowth = a[8244]?.top_growing?.yoy_growth || null;
              const bYoyGrowth = b[8244]?.top_growing?.yoy_growth || null;

              if (bYoyGrowth == null && aYoyGrowth == null) {
                return 0;
              } else if (bYoyGrowth == null) {
                return -1;
              } else if (aYoyGrowth == null) {
                return 1;
              }

              return bYoyGrowth - aYoyGrowth;
            }
            return 0;
          })
          .sort((a, b) => {
            if (a["player"].isSubscribed && !b["player"].isSubscribed) {
              return -1;
            }
            if (!a["player"].isSubscribed && b["player"].isSubscribed) {
              return 1;
            }
            return 0;
          })
          .sort((a, b) => {
            if (a["player"].bookMarkValue === 0) return 1;
            else if (b["player"].bookMarkValue === 0) return -1;
            else if (a["player"].bookMarkValue > b["player"].bookMarkValue)
              return 1;
            else if (a["player"].bookMarkValue < b["player"].bookMarkValue)
              return -1;
            return a["player"].bookMarkValue - a["player"].bookMarkValue;
          })
      );
    }
  }, [
    visibleCompanies,
    parameterRange,
    parameterValueFilter,
    isReportMappingLoaded,
    bookmarkedSectors,
  ]);

  const setSortedColumn = (sortedCol) => {
    setSortedParameter(sortedCol);
  };

  useEffect(() => {
    if (sortedParameter) {
      const sortedRows = [...styledRowsData]
        .sort((a, b) => {
          const aVal = a[sortedParameter]?.sortingValue;
          const bVal = b[sortedParameter]?.sortingValue;
          if (aVal == null && bVal == null) {
            return 0;
          } else if (aVal == null) {
            return 1;
          } else if (bVal == null) {
            return -1;
          }

          if (typeof aVal === "number" && typeof bVal === "number") {
            return sortedDirection === "asc" ? aVal - bVal : bVal - aVal;
          } else if (typeof aVal === "string" && typeof bVal === "string") {
            return sortedDirection === "asc"
              ? aVal.localeCompare(bVal)
              : bVal.localeCompare(aVal);
          } else {
            return 0;
          }
        })
        .sort((a, b) => {
          if (a["player"].isSubscribed && !b["player"].isSubscribed) {
            return -1;
          }
          if (!a["player"].isSubscribed && b["player"].isSubscribed) {
            return 1;
          }
          return 0;
        })
        .sort((a, b) => {
          if (a["player"].bookMarkValue === 0) return 1;
          else if (b["player"].bookMarkValue === 0) return -1;
          else if (a["player"].bookMarkValue > b["player"].bookMarkValue)
            return 1;
          else if (a["player"].bookMarkValue < b["player"].bookMarkValue)
            return -1;
          return a["player"].bookMarkValue - a["player"].bookMarkValue;
        });
      setStyledRowsData(sortedRows);
    }
  }, [sortedParameter, sortedDirection]);

  const stringContains = (target, query) => {
    const targetString = target.replace(/\s/g, "").toLowerCase();
    const queryString = query.replace(/\s/g, "").toLowerCase();
    return targetString.includes(queryString);
  };

  const tagsCompare = (comTags, selectedTag) => {
    // return comTags.some((obj1) =>
    //   selectedTag.some((obj2) => obj1.id === obj2.id)
    // );
    return selectedTag.every((obj2) =>
      comTags.some((obj1) => obj1.id === obj2.id)
    );
  };

  useEffect(() => {
    let selectedTags = tags.filter((tag) => tag.selected) || tags;
    const selectedIndustryIdList = industryList
      .filter((ind) => ind.selected)
      .map((ind) => ind.industry_id);

    const selectedValuationList = valuationList.filter(
      (series) => series.selected
    );

    const newFilteredCompanies = allCompanies
      .filter(
        (comp) =>
          stringContains(comp.player_name, search_player) ||
          stringContains(comp.industry_name, search_player)
      )
      .filter((comp) =>
        selectedTags.length === 0 ? true : tagsCompare(comp.tags, selectedTags)
      )
      .filter((comp) =>
        selectedIndustryIdList.length === 0
          ? true
          : selectedIndustryIdList.includes(comp.industry_id)
      )
      .filter((comp) => {
        if (selectedValuationList.length) {
          if (comp.valuation) {
            if (
              selectedValuationList.some(
                (range) =>
                  comp.valuation >= range.low && comp.valuation <= range.high
              )
            )
              return true;
            return false;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
    setVisibleCompanies(newFilteredCompanies);
    setColumnsData(
      columnsData.map((col) => {
        return {
          ...col,
          value:
            col.key === "player"
              ? getNameHeader(newFilteredCompanies.length)
              : col.value,
        };
      })
    );
  }, [tags, search_player, industryList, valuationList, allCompanies]);

  function getNodesAtLevel(data, level, result = []) {
    data.forEach((node) => {
      if (node.level === level) {
        result.push(node);
      }
      if (node.children && node.children.length > 0) {
        getNodesAtLevel(node.children, level, result);
      }
    });
    return result;
  }

  function groupLevel5NodesByParentLevel3(data) {
    const result = {};

    function traverse(nodes) {
      nodes.forEach((node) => {
        if (node.level === 3 && node.children && node.children.length > 0) {
          node.children.forEach((child) => {
            if (
              child.level === 4 &&
              child.children &&
              child.children.length > 0
            ) {
              if (!result[node.industry_id]) {
                result[node.industry_id] = [];
              }
              result[node.industry_id].push(
                ...child.children.map((level5Node) => ({
                  ...level5Node,
                  sectorNode: node,
                }))
              );
            }
          });
        }
        if (node.children && node.children.length > 0) {
          traverse(node.children);
        }
      });
    }

    traverse(data);

    return result;
  }

  const handleRedirect = () => {
    navigate("/company-listing-new");
  };
  const filteredColumnsData = columnsData.filter(item => item.key === 'player');
//For removing redseer score from the table
  return (
    <>
      <PageWrapper>
        <PlayerHeader>Platform Listing</PlayerHeader>
        <DataSection>
          <ShadowWrap>
            {isDataLoaded ? (
              <>
                <HeaderSection>
                  <TableMenuLeft>
                    For the {frequency === "quarterly" ? "Quarter" : "Month"} of{" "}
                    {getLabel(tableDate)}
                  </TableMenuLeft>

                  {/* <CompanyInputWrapper>
                <CompanyInput
                  placeholder="Filter subscribed players"
                  type="search"
                  value={compSearchString}
                  onChange={(e) => {
                    setCompSearchString(e.target.value);
                  }}
                ></CompanyInput>
                <SearchWrapper></SearchWrapper>
              </CompanyInputWrapper> */}
                  {/* <FilterWarp>
                <PlayerSubHeader
                  selected={selectedHeader === "Subscribed"}
                  onClick={() => handleHeaderClick("Subscribed")}
                >
                  Subscribed
                </PlayerSubHeader>
                <PlayerSubHeader
                  selected={selectedHeader === "Top Rated"}
                  onClick={() => handleHeaderClick("Top Rated")}
                >
                  Top Rated
                </PlayerSubHeader>
                <PlayerSubHeader
                  selected={selectedHeader === "Top Growing"}
                  onClick={() => handleHeaderClick("Top Growing")}
                >
                  Top Growing
                </PlayerSubHeader>
              </FilterWarp> */}
                </HeaderSection>
                {columnsData.length > 1 ? (
                  <MasterTable
                    columnsData={filteredColumnsData}
                    rowsData={
                      styledRowsData.length > 10
                        ? styledRowsData.slice(0, 10)
                        : styledRowsData
                    }
                    sortedColKey={sortedParameter}
                    setSortedColKey={setSortedParameter}
                    sortedDirection={sortedDirection}
                    setSortedDirection={setSortedDirection}
                    headerBackground={"#FFFFFF"}
                    onRangeFilterApply={onRangeFilterApply}
                    filteredRange={parameterRange}
                    valueFilterData={parameterValueFilter}
                    onValueFilterApply={onValueFilterApply}
                  />
                ) : null}
              </>
            ) : (
              <>
                <LoadingMessage>Loading...</LoadingMessage>
                <Loader position="absolute" height="100%" bgColor="#FFFFFF60" />
              </>
            )}
          </ShadowWrap>
        </DataSection>

        <>
          <Loaddiv>
            <>
              <Textdiv onClick={handleRedirect}>
                {
                  <>
                    <RedirectTextWarp>Click for more</RedirectTextWarp>
                  </>
                }
                <IoIosArrowForward />
              </Textdiv>
            </>
          </Loaddiv>
        </>
      </PageWrapper>
    </>
  );
};

export default PlayerListing;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f7fcff;
`;

const Header1 = styled.div`
  background-color: white;
  padding: 3.5vw;
  padding-top: 2vw;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
`;

const LHSTitlediv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
  font-weight: 500;
  font-size: 30px;
`;

const CompanyInputWrapper = styled.div`
  display: flex;
  width: 30%;
  height: 40px;
`;

const CompanyInput = styled.input`
  width: 100%;
  height: 100%;
  padding: 14px 32px 14px 56px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 30px;
  color: #3c4043;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid var(--grey-dark, #d1d1d1);
  ::placeholder {
    color: #bfbfbf;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  :hover,
  :focus {
    border: 1px solid #09f;
    background: var(--white, #fff);
    outline: none;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 22px;
  top: 5px;
`;

const CpProfileLink = styled.div`
  color: ${(props) => (props.islink === "true" ? "#0099FF" : "#000000")};
  cursor: ${(props) => (props.islink === "true" ? "pointer" : "default")};
  :hover {
  }
  display: flex;
  white-space: nowrap;
  justify-content: flex-start;
  width: 100%;
  font-size: 18px;
  ${media.small`
font-size:12px;
`}
`;

const SpProfileLink = styled.div`
  color: ${(props) => (props.islink === "true" ? "#A0A6A9" : "#A0A6A9")};
  text-decoration: ${(props) =>
    props.islink === "true" ? "underline" : "none"};
  font-size: 12px;
  font-style: italic;
  font-weight: 350;
  line-height: 16px;
  letter-spacing: 0em;
  display: flex;
  text-align: left;
  cursor: ${(props) => (props.islink === "true" ? "pointer" : "default")};
  :hover {
    color: ${(props) => (props.islink === "true" ? "#A0A0A0" : "#A0A6A9")};
  }
  ${media.small`
font-size:10px;
`}
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 700px;
  display:flex;
  ${media.small`
  font-size:12px;
  `}
`;

const CountTextWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CountText = styled.div`
  color: #444444;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-left: 10px;
  ${media.small`
  font-size: 12px;
  padding-left: 5px;
  line-height: 12px;

  `}
`;
const HeaderNameDiv = styled.div`
  font-size: 16px;

  ${media.small`
  font-size: 12px;
 

  `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Element1 = styled.div`
  font-size: 20px;
  font-weight: 700;
  ${media.small`
  font-size:14px;
  `}
`;

const Element2 = styled.div`
  font-size: 10px;
  font-weight: 400;
  ${media.small`
  font-size:10px;
  `}
`;

const Element3 = styled.div`
  font-size: 10px;
  font-weight: 300;
  ${media.small`
  font-size:10px;
  `}
`;

const Growthspan = styled.span`
  background-color: ${(props) => (props.growth >= 0 ? "#d4efdc" : "#f8d2d4")};
  margin-left: 10px;
  padding: 10px;
  border-radius: 20px;
`;

const Loaddiv = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 2px;
  background-color: #d9d9d9;
  margin: 3.5vw;
`;

const Textdiv = styled.span`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f7fcff;
  padding: 0 10px;
  cursor: pointer;
  color: #5cbeff;
`;

const USDWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 3.5vw 0px;

  margin-bottom: 1%;
`;

const BreadCrumbSpan = styled.span`
  &:hover {
    color: #20a6ff;
    cursor: pointer;
  }
`;

const DataSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
`;

const PlayerHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 3.5vw 0px;
  font-weight: 500;
  font-size: 30px;
  ${media.small`
  font-size:20px;
  `}
`;

const ShadowWrap = styled.div`
  box-shadow: 2px 2px 4px 0px #00000040;
  position: relative;
`;

const SectorProfile = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 15px 15px;
  background-color: #ffffff;
`;

const TableMenuLeft = styled.div`
  width: 20%;
  ${media.small`
width:50%;
font-size:12px;
`}
`;

const PlayerSubHeader = styled.div`
  width: 100%;
  height: 40px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  font-size: 13.5px;
  cursor: pointer;
  align-items: center;
  background-color: ${(props) => (props.selected ? "#adddf4" : "transparent")};
  :hover {
    background-color: #adddf4;
  }
  ${media.small`
  height: 20px;
  font-size:8px;
  `}

  ${media.medium`
  height: 30px;
  font-size:10px;
  `}
`;

const StyledImage = styled.img`
  width: 50px;
  height: 50px;
  padding: 1px;
  margin-right: 5px;
  border: 0.5px solid #d1d1d1;
  border-radius: 5px;
  ${media.small`
  width: 40px;
  height: 40px;
  padding: 0.5px;
  margin-right: 3px;
  border: 0.25px solid #d1d1d1;
  border-radius: 3px;
  display:none;
  `}
`;

const RedseerScoreWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const IndustrySizeWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  height: 100%;
  width: 100%;
`;

const FilterWarp = styled.div`
  width: 20%;
  height: 40px;
  display: flex;
  ${media.small`
width:50%;
align-items:center;
`}
`;

const YoyFontWrap = styled.div`
  font-size: 14px;
  ${media.small`
  font-size:9px;
  padding-left: 4px;
`}
`;

const PlayerWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const CompanyNameWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  // background: red;
  ${media.small`
  // justify-content: flex-start;
  `}
`;

const ColumnWarp = styled.div`
  display: flex;
  flex-direction: column;
  // background:blue;
`;

const RedirectTextWarp = styled.span`
  font-size: 15px;
  ${media.small`
font-size: 10px;
`}
`;

const TagHover = styled.div`
  display: flex;
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  margin-right: 5%;

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`;

const Tooltip = styled.div`
  visibility: hidden;
  opacity: 1;
  width: auto;
  height: 100%;
  padding: 1px 5px;
  font-size: 9px;
  white-space: nowrap;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 5px;
  padding-bottom: 0;
  position: absolute;
  z-index: 15;
  bottom: 100%;
  transition: opacity 0.3s;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
`;

const LoadingMessage = styled.div`
  min-height: 360px;
  padding: 15px 15px 15px;
`;
